import React, {useEffect, useState} from "react";
import {ticketListFxn, releasePnrFxn} from "./action";
import moment from "moment";
import {useDispatch} from "react-redux";
import {Tooltip, Popconfirm} from "../../components/Elements";

const HoldTicket = () => {
    const dispatch = useDispatch()
    const [ticketList, setTicketList] = useState([]);
    const events = {
        ticketList: async () => {
            let {data} = await dispatch(ticketListFxn());
            setTicketList(data);
        },
        releasePnr: async (bookingId, traceId) => {
            let resp = await dispatch(releasePnrFxn({bookingId: bookingId, traceId: traceId}))
        }
    };

    useEffect(() => {
        events.ticketList();
    }, []);

    return (
        <div className="row row-gap-3">
            <div className="col-lg-12">
                <h4 className="mb-4 ">Booking Queue</h4>
                <div className="ticket-list pe-3">
                    {ticketList.map((ticket, index) => {
                        let {bookingResponse} = ticket;
                        return bookingResponse && bookingResponse?.ResponseStatus == "1" ? (
                            <>
                                <div className="track-flights" key={index}>
                                    <div
                                        className="accordion"
                                        id={`accordionPanelsStayOpenExample${index}`}
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id={`panelsStayOpen-heading${index}`}
                                            >
                                                <ul>
                                                    <li className="p-3 head-accordion manageBookingList">
                                                        <h6 className="pnr">

                                                                <div>
                                                                    PNR:&nbsp;
                                                                    {ticket &&
                                                                    ticket.bookingResponse &&
                                                                    ticket.bookingResponse.Response &&
                                                                    ticket.bookingResponse.Response.PNR}
                                                                    <a
                                                                        href={`/response/${ticket?._id}`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className={'ms-2'}>
                                                                        <img
                                                                            src="/images2/eye.png"
                                                                            width="20"
                                                                            alt="eye-icon"
                                                                        />
                                                                    </a>
                                                                </div>

                                                            <small className={'d-block'}>
                                                                Booking Date:&nbsp;
                                                                {ticket &&
                                                                ticket.createdAt &&
                                                                moment(ticket.createdAt).format('DD/MMM/YY')}
                                                            </small>
                                                        </h6>
                                                        <div className={'d-flex'}>
                                                            <img className="airlineLogoSize"
                                                                 src={`../airlineLogos/${
                                                                     ticket &&
                                                                     ticket.bookingResponse &&
                                                                     ticket.bookingResponse.Response &&
                                                                     ticket.bookingResponse.Response.FlightItinerary &&
                                                                     ticket.bookingResponse.Response.FlightItinerary.Segments &&
                                                                     ticket.bookingResponse.Response.FlightItinerary.Segments.length > 0 &&
                                                                     ticket.bookingResponse.Response.FlightItinerary.Segments[0].Airline &&
                                                                     ticket.bookingResponse.Response.FlightItinerary.Segments[0].Airline.AirlineCode &&
                                                                     ticket.bookingResponse.Response.FlightItinerary.Segments[0].Airline.AirlineCode
                                                                 }.gif`} alt=""/>
                                                            <h6 className="name">
                                                                {ticket &&
                                                                ticket.bookingResponse &&
                                                                ticket.bookingResponse.Response &&
                                                                ticket.bookingResponse.Response.FlightItinerary &&
                                                                ticket.bookingResponse.Response.FlightItinerary.Segments &&
                                                                ticket.bookingResponse.Response.FlightItinerary.Segments.length > 0 &&
                                                                ticket.bookingResponse.Response.FlightItinerary.Segments[0].Airline &&
                                                                ticket.bookingResponse.Response.FlightItinerary.Segments[0].Airline.AirlineName &&
                                                                ticket.bookingResponse.Response.FlightItinerary.Segments[0].Airline.AirlineName}
                                                                <small className="d-block">
                                                                    {ticket &&
                                                                    ticket.bookingResponse &&
                                                                    ticket.bookingResponse.Response &&
                                                                    ticket.bookingResponse.Response.FlightItinerary &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Segments &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Segments.length > 0 &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Segments[0].Origin &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Segments[0].Origin.DepTime &&
                                                                    moment(ticket.bookingResponse.Response.FlightItinerary.Segments[0].Origin.DepTime).format('DD/MM/YY')}
                                                                </small>
                                                            </h6>

                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <h6 style={{
                                                                justifyContent: "center",
                                                                textAlign: "center",
                                                            }}>
                                                                <small className="d-block">
                                                                    {ticket &&
                                                                    ticket.bookingResponse &&
                                                                    ticket.bookingResponse.Response &&
                                                                    ticket.bookingResponse.Response.FlightItinerary &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Origin &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Origin}
                                                                    <br/>
                                                                    {ticket &&
                                                                    ticket.bookingResponse &&
                                                                    ticket.bookingResponse.Response &&
                                                                    ticket.bookingResponse.Response.FlightItinerary &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Segments &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Segments.length > 0 &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Segments[0].Origin &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Segments[0].Origin.DepTime &&
                                                                    moment(ticket.bookingResponse.Response.FlightItinerary.Segments[0].Origin.DepTime).format('HH:MM A')}
                                                                </small>
                                                            </h6>
                                                            <span className="mx-4">
                                                                <img src="images2/plane.png" alt="plane"/>
                                                              </span>
                                                            <h6
                                                                style={{
                                                                    justifyContent: "center",
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                <small className="d-block me-3">
                                                                    {ticket &&
                                                                    ticket.bookingResponse &&
                                                                    ticket.bookingResponse.Response &&
                                                                    ticket.bookingResponse.Response.FlightItinerary &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Destination &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Destination}
                                                                    <br/>
                                                                    {ticket &&
                                                                    ticket.bookingResponse &&
                                                                    ticket.bookingResponse.Response &&
                                                                    ticket.bookingResponse.Response.FlightItinerary &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Segments &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Segments.length > 0 &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Segments[0].Origin &&
                                                                    ticket.bookingResponse.Response.FlightItinerary.Segments[0].Origin.DepTime &&
                                                                    moment(ticket.bookingResponse.Response.FlightItinerary.Segments[0].Origin.ArrTime).format('HH:MM A')}
                                                                </small>
                                                            </h6>
                                                        </div>
                                                        {/*<h6 className="passengerBlock d-flex">*/}
                                                        {/*    {ticket &&*/}
                                                        {/*    ticket.Passengers &&*/}
                                                        {/*    ticket.Passengers.map((passenger, index) => (*/}
                                                        {/*        <a*/}
                                                        {/*            href={`/ticket-details?ticketId=${ticket?._id}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}`}*/}
                                                        {/*            target="_blank"*/}
                                                        {/*            key={index}*/}
                                                        {/*            className={'passengersStyle'}>*/}
                                                        {/*            <small className="d-block">*/}
                                                        {/*                <Tooltip*/}
                                                        {/*                    title={`View Ticket - ${passenger.FirstName} ${passenger.LastName}`}>*/}
                                                        {/*                    {passenger.FirstName} {passenger.LastName}*/}
                                                        {/*                    {index !== ticket.Passengers.length - 1 && ','}*/}
                                                        {/*                </Tooltip>*/}
                                                        {/*            </small>*/}
                                                        {/*        </a>*/}
                                                        {/*    ))}*/}
                                                        {/*</h6>*/}

                                                        <div className={'d-flex align-items-center'}>

                                                            <Popconfirm
                                                                title="Are you sure you want to release this PNR?"
                                                                onConfirm={() => events.releasePnr(bookingResponse?.Response?.BookingId, bookingResponse?.TraceId)}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <button
                                                                    className={'btn btn-primary fw-semibold buttonFontSize gradient-button'}
                                                                >
                                                                    Release
                                                                </button>
                                                            </Popconfirm>

                                                            <a
                                                                href={`/response/${ticket?._id}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className={'btn btn-primary fw-semibold buttonFontSize gradient-button ms-2'}>

                                                                Open
                                                            </a>


                                                                <p
                                                                   className="mr10 ms-2" style={{fontSize: '14px'}}
                                                                   >
                                                                  PNR Limit Time:
                                                                </p> <br/>

                                                            {/*<p>PNR Limit Time :</p>*/}

                                                            {/*<Tooltip title={`Delete`}>*/}
                                                            {/*    <a href={''} className="btn p-0 mr10">*/}
                                                            {/*        <img*/}
                                                            {/*            src="..//images2/delete.png"*/}
                                                            {/*            width={28}*/}
                                                            {/*            alt="eye-icon"*/}
                                                            {/*        />*/}
                                                            {/*    </a>*/}
                                                            {/*</Tooltip>*/}
                                                            {/*<a*/}
                                                            {/*    href=""*/}
                                                            {/*    data-bs-toggle="collapse"*/}
                                                            {/*    data-bs-target={`#panelsStayOpen-collapse${index}`}*/}
                                                            {/*    aria-expanded="true"*/}
                                                            {/*    aria-controls={`panelsStayOpen-collapse${index}`}*/}
                                                            {/*    className="btn p-0">*/}
                                                            {/*    <img*/}
                                                            {/*        src="../images2/arrow-down.png"*/}
                                                            {/*        alt="arrow-down"*/}
                                                            {/*        width={28}*/}
                                                            {/*    />*/}
                                                            {/*</a>*/}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </h2>
                                            <div
                                                id={`panelsStayOpen-collapse${index}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`panelsStayOpen-heading${index}`}
                                            >
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li className="manageBookingList">
                                                            <h6>
                                                                {ticket &&
                                                                ticket.Passengers &&
                                                                ticket.Passengers.length
                                                                    ? ticket.Passengers.length
                                                                    : 0}{" "}
                                                                <small className="d-block">Passengers</small>
                                                            </h6>
                                                            <h6>
                                                                {ticket &&
                                                                ticket.bookingResponse &&
                                                                ticket.bookingResponse.Response &&
                                                                ticket.bookingResponse.Response.FlightItinerary &&
                                                                ticket.bookingResponse.Response.FlightItinerary
                                                                    .Segments &&
                                                                ticket.bookingResponse.Response.FlightItinerary
                                                                    .Segments[0] &&
                                                                ticket.bookingResponse.Response.FlightItinerary
                                                                    .Segments[0].Origin &&
                                                                ticket.bookingResponse.Response.FlightItinerary
                                                                    .Segments[0].Origin.DepTime &&
                                                                moment(
                                                                    ticket.bookingResponse.Response.FlightItinerary
                                                                        .Segments[0].Origin.DepTime
                                                                ).format("DD MMM, YY")}
                                                                <small className="d-block">Departure</small>
                                                            </h6>
                                                            <h6>
                                                                Departure Time{" "}
                                                                <small className="d-block">
                                                                    {ticket &&
                                                                    ticket.bookingResponse &&
                                                                    ticket.bookingResponse.Response &&
                                                                    ticket.bookingResponse.Response.FlightItinerary &&
                                                                    ticket.bookingResponse.Response.FlightItinerary
                                                                        .Segments &&
                                                                    ticket.bookingResponse.Response.FlightItinerary
                                                                        .Segments[0] &&
                                                                    ticket.bookingResponse.Response.FlightItinerary
                                                                        .Segments[0].Origin &&
                                                                    ticket.bookingResponse.Response.FlightItinerary
                                                                        .Segments[0].Origin.DepTime &&
                                                                    moment(
                                                                        ticket.bookingResponse.Response.FlightItinerary
                                                                            .Segments[0].Origin.DepTime
                                                                    ).format("HH:mm A")}
                                                                </small>
                                                            </h6>
                                                            <h6>
                                                                {ticket &&
                                                                ticket.bookingResponse &&
                                                                ticket.bookingResponse.Response &&
                                                                ticket.bookingResponse.Response.FlightItinerary &&
                                                                ticket.bookingResponse.Response.FlightItinerary
                                                                    .Segments &&
                                                                ticket.bookingResponse.Response.FlightItinerary
                                                                    .Segments[0] &&
                                                                ticket.bookingResponse.Response.FlightItinerary
                                                                    .Segments[0].Destination &&
                                                                ticket.bookingResponse.Response.FlightItinerary
                                                                    .Segments[0].Destination.ArrTime &&
                                                                moment(
                                                                    ticket.bookingResponse.Response.FlightItinerary
                                                                        .Segments[0].Destination.ArrTime
                                                                ).format("DD MMM, YY")}
                                                                <small className="d-block">Arrival</small>
                                                            </h6>
                                                            <h6>
                                                                Arrival Time{" "}
                                                                <small className="d-block">
                                                                    {ticket &&
                                                                    ticket.bookingResponse &&
                                                                    ticket.bookingResponse.Response &&
                                                                    ticket.bookingResponse.Response.FlightItinerary &&
                                                                    ticket.bookingResponse.Response.FlightItinerary
                                                                        .Segments &&
                                                                    ticket.bookingResponse.Response.FlightItinerary
                                                                        .Segments[0] &&
                                                                    ticket.bookingResponse.Response.FlightItinerary
                                                                        .Segments[0].Destination &&
                                                                    ticket.bookingResponse.Response.FlightItinerary
                                                                        .Segments[0].Destination.ArrTime &&
                                                                    moment(
                                                                        ticket.bookingResponse.Response.FlightItinerary
                                                                            .Segments[0].Destination.ArrTime
                                                                    ).format("HH:mm A")}
                                                                </small>
                                                            </h6>
                                                            <h6>
                                                                Class:{" "}
                                                                <small className="d-block">Business</small>
                                                            </h6>
                                                            <h6>
                                                                Journey Time:{" "}
                                                                <small className="d-block">
                                                                    {ticket &&
                                                                    ticket.bookingResponse &&
                                                                    ticket.bookingResponse.Response &&
                                                                    ticket.bookingResponse.Response.FlightItinerary &&
                                                                    ticket.bookingResponse.Response.FlightItinerary
                                                                        .Segments &&
                                                                    ticket.bookingResponse.Response.FlightItinerary
                                                                        .Segments[0].Duration &&
                                                                    `${Math.floor(
                                                                        ticket.bookingResponse.Response.FlightItinerary
                                                                            .Segments[0].Duration / 60
                                                                    )}h ${
                                                                        ticket.bookingResponse.Response.FlightItinerary
                                                                            .Segments[0].Duration % 60
                                                                    }m`}
                                                                </small>
                                                            </h6>
                                                        </li>
                                                        {/* <div
                                                            className="d-flex flex-row"
                                                            style={{
                                                                border: "1px solid #ddd",
                                                                borderRadius: "8px",
                                                                padding: "10px",
                                                                width: "fit-content",
                                                            }}
                                                        >
                                                            <h6 className={'mt10'}>Passengers:</h6>
                                                            {ticket && ticket.Passengers && (
                                                                <small className="btn btn-link">
                                                                    {ticket.Passengers.map((passenger, idx) => (
                                                                        <a
                                                                            className={idx > 0 ? 'mt10' : ''}
                                                                            key={idx}
                                                                            // href={`/ticket-details?bookingId=${ticket?.bookingResponse?.Response?.BookingId}&pnr=${ticket?.bookingResponse?.Response?.PNR}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}&traceId=${ticket?.TraceId}`}
                                                                            // target="_blank"
                                                                            // rel="noopener noreferrer"
                                                                            href={`/ticket-details?ticketId=${ticket?._id}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}`}
                                                                            target="_blank"
                                                                        >
                                                                            {idx + 1}. {passenger.FirstName} {passenger.LastName}
                                                                            <br/>
                                                                        </a>

                                                                    ))}
                                                                </small>
                                                            )}

                                                        </div>*/}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null;
                    })}
                </div>
            </div>
        </div>
    );
};

export default HoldTicket;
