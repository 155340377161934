import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {airLinesFxn} from "../actions";
import {DatePicker, Form, notification, Radio, Select} from "../../../components/Elements";
import CityTypeheadComponent from "../../../components/CityTypeheadComponent";
import {CabinComponent, FareTypesComponent, PassengerComponent} from "./flightSearchComponent";
import moment from "moment";

const RoundTripSearch = (props) => {
    let {journeyType, onClick, flightObj, state, setState} = props;
    let dispatch = useDispatch()
    let [airlineList, setAirlineList] = useState([])
    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        loadAirLine: async () => {
            let params = {
                results: 10000,
                count: 10000,
            }
            let {data} = await airLinesFxn(params);
            setAirlineList(data)
        },
        handleSearchFlight: async () => {
            if (!state.origin) {
                return notification.warning({message: "Please choose Departing From."})
            }
            if (!state.destination) {
                return notification.warning({message: "Please choose Going To."})
            }
            if (!state.preferredDepartureTime) {
                return notification.warning({message: "Please choose Departure Date."})
            }
            if (!state.preferredReturnTime && journeyType == "2") {
                return notification.warning({message: "Please choose Return Date."})
            }
            if (state.adultCount == 0 && state.childCount == 0 && state.infantCount == 0) {
                return notification.warning({message: "Please choose Passenger."})
            }
            let obj = {
                ...state, journeyType
            }
            dispatch({type: "SEARCH_FLIGHT", flightObj: obj})
            onClick(obj)
        }
    }
    useEffect(() => {
        events.loadAirLine()
    }, [])
    useEffect(() => {
        if (flightObj) {
            if (flightObj.preferredDepartureTime) {
                flightObj.preferredDepartureTime = moment(flightObj.preferredDepartureTime)
            }
            if (flightObj.preferredReturnTime) {
                flightObj.preferredReturnTime = moment(flightObj.preferredReturnTime)
            }
            setState((prevState) => {
                return {
                    ...prevState,
                    ...flightObj
                }
            })
        }
    }, [flightObj])

    useEffect(() => {
        if (journeyType == "1"){
            events._updateState({preferredReturnTime: null})
        }
    }, [journeyType])

    const disabledDate = (current, fromDate) => {
        return current && current.isBefore(fromDate, 'day');
    };


    return (
        <>
            <Form>
                <div className="row row-gap-3">
                    <div className={'col-md-4'} key={state.origin}>
                        <CityTypeheadComponent
                            placeholder={"Departing From?"}
                            cityId={state.origin} onSelect={(value) => {
                            events._updateState({origin: value})
                        }}/>
                    </div>
                    <div className={'col-md-4'} key={state.destination}>
                        <CityTypeheadComponent
                            placeholder={"Going To?"}
                            cityId={state.destination} onSelect={(value) => {
                            events._updateState({destination: value})
                        }}/>
                    </div>
                    <div className={journeyType == "1" ? 'col-md-4' : "col-md-2"}>
                        <DatePicker
                            placeholder={'Departure Date'}
                            value={state.preferredDepartureTime}
                            disabledDate={(current) => disabledDate(current, moment())}
                            onChange={(value) => {
                                events._updateState({preferredDepartureTime: value})
                            }}/>
                    </div>
                    {journeyType == "2" ?
                        <div className={'col-md-2'}>
                            <DatePicker
                                defaultValue={state.preferredReturnTime}
                                placeholder={'Return Date'}
                                disabledDate={(current) => disabledDate(current, moment(state.preferredReturnTime))}
                                value={state.preferredReturnTime}
                                onChange={(value) => {
                                    events._updateState({preferredReturnTime: value})
                                }}/>
                        </div> : null}
                    <div className={'col-md-4'}>
                        <Select
                            options={airlineList}
                            keyAccessor={(option) => option.airLineCode}
                            valueAccessor={(option) => option.airLineName}
                            placeholder={"Preferred Airline"}
                            required={true}
                            showSearch={true}
                            multiple={true}
                            allowClear={true}
                            onChange={(value) => {
                                events._updateState({preferredAirlines: value})
                            }}
                        />
                    </div>
                    <div className="col-lg-4 col-12">
                        <CabinComponent state={state} onChange={(data) => {
                            events._updateState(data)
                        }}/>
                    </div>
                    <div className="col-lg-4 col-12">
                        <PassengerComponent state={state} onChange={(data) => {
                            events._updateState(data)
                        }}/>
                    </div>

                    <div className={'col-lg-12 col-12 mt-4'}>
                        <FareTypesComponent value={state.resultFareType} onClick={(data) => {
                            events._updateState({resultFareType: data})
                        }}/>
                    </div>
                </div>
                <button onClick={() => {
                    events.handleSearchFlight()
                }} className="btn btn-primary mt-4 w-100">
                    Find Your Flight
                </button>
            </Form>
        </>
    )
}

export default RoundTripSearch
