import {ticketListUrl, getBookingDetailsUrl, bookSeatsUrl, singleTicketUrl, releasePnrUrl} from "./api";
import {customAxios as axios} from "../../request";
import {hideLoader, showLoader} from "../../actions/loader";
import {flightsFearQuoteUrl} from "../flightSearch/apis";
import {notification} from "../../components/Elements";

export const ticketListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        params: {...valData}
    }
    let {data} = await axios.get(ticketListUrl(), config)
    dispatch(hideLoader())
    return data.data;
}

export const getBookingDetailsFxn = (valData) => async (dispatch) => {
    let config = {
        params: {...valData}
    }
    dispatch(showLoader());
    let {data} = await axios.get(getBookingDetailsUrl(), config)
    dispatch(hideLoader())
    return data;
}

export const bookSeatsFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.post(bookSeatsUrl(), valData)
    console.log(data, '-----req ssr');
    dispatch(hideLoader())
    if (!data.error) {
        // notification.success({
        //     message: data.message || 'Success Book Seat'
        // })
    } else {
        // notification.error({
        //           message: data.message || 'Error'
        //       })
    }
    return data;
}

export const singleTicketFxn = (valData) => async (dispatch) => {
    let config = {
        params: {...valData}
    }
    dispatch(showLoader());
    let {data} = await axios.get(singleTicketUrl(), config)
    dispatch(hideLoader())
    return data;
}

export const releasePnrFxn = (valData) => async (dispatch) => {
    let {data} = await axios.post(releasePnrUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
                  message: data.message || 'Error'
              })
    }
    return data;
}


