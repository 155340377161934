import _ from "lodash"

export const getUniqueAirlines = (flights) => {
    const airlines = [];
    flights.forEach((flight) => {
        flight.Segments.forEach((segmentArray) => {
            segmentArray.forEach((segment) => {
                const {AirlineName} = segment.Airline;
                const {PublishedFare} = flight.Fare;
                if (!airlines.some((a) => a.name === AirlineName)) {
                    airlines.push({
                        name: AirlineName,
                        publishedFare: PublishedFare,
                        segments: flight.Segments,
                    });
                }
            });
        });
    });
    return airlines;
};

export const getUniqueFareTypes = (flights) => {
    const fareTypes = [];
    flights.forEach((flight) => {
        const {Type = 'Publish'} = flight?.FareClassification || {};
        if (Type && !fareTypes.includes(Type)) {
            fareTypes.push(Type);
        }
    });
    return fareTypes;
};
export const getStops = (flights) => {
    const fareTypes = [];
    flights.forEach((flight) => {
        const segments = flight.Segments[0];
        let stopName = segments.length
        if (stopName && !fareTypes.includes(stopName)) {
            fareTypes.push(stopName);
        }
    });
    return _.sortBy(fareTypes);
};

export const calculateCheapestAndFastestFlights = (flightList) => {
    return flightList.reduce(
        (acc, flight) => {
            const totalFlightDuration = flight.Segments.reduce(
                (total, segmentArray) =>
                    total + segmentArray.reduce((segTotal, segment) => segTotal + segment.Duration, 0),
                0
            );

            if (flight.Fare.PublishedFare < acc.cheapestFlight || acc.cheapestFlight === 0) {
                acc.cheapestFlight = flight.Fare.PublishedFare;
                acc.cheapestFlightDuration = totalFlightDuration;
                acc.cheapestFlightDetails = flight;
            }

            if (totalFlightDuration < acc.fastestFlightDuration || acc.fastestFlightDuration === Infinity) {
                acc.fastestFlightDuration = totalFlightDuration;
                acc.fastestFlight = flight.Fare.PublishedFare;
                acc.fastestFlightDetails = flight;
            }

            return acc;
        },
        {
            cheapestFlight: 0,
            cheapestFlightDuration: "N/A",
            fastestFlightDuration: Infinity,
            fastestFlight: 0,
            cheapestFlightDetails: null,
            fastestFlightDetails: null,
        }
    );
};


export const getLayoverCity = (flights) => {
    const fareTypes = [];
    flights.forEach((flight) => {
        const segments = _.clone(flight.Segments[0]);
        segments.shift()
        _.each(segments, (item) => {
            if (item && item.Origin && item.Origin.Airport && item.Origin.Airport.AirportCode) {
                let findD = _.find(fareTypes, (ii) => {
                    return ii.airportCode == item.Origin.Airport.AirportCode
                })
                if (!findD) {
                    fareTypes.push({
                        airportCode: item.Origin.Airport.AirportCode,
                        airportName: item.Origin.Airport.AirportName,
                    })
                }
            }
        })
    });
    return _.sortBy(fareTypes, 'airportName');
};

export const groupFlightsFxn = (flights) => {
    /*   const groupedFlights = _.groupBy(flights, (flight) => {
           if (flight.Segments && flight.Segments[0] && flight.Segments[0][0] && flight.Segments[0][0].Airline) {
               const { AirlineCode, FlightNumber, FareClass } = flight.Segments[0][0].Airline;
               return `${AirlineCode}-${FlightNumber}-${FareClass}`;
           } else {
               return "Unknown-Group";
           }
       });*/

    const groupedFlights = _.groupBy(flights, (flight) => {
        const airline = _.get(flight, 'Segments[0][0].Airline');
        if (airline) {
            const {AirlineCode, FlightNumber, FareClass} = airline;
            return `${AirlineCode}-${FlightNumber}-${FareClass}`;
        }
        return "Unknown-Group";
    });

    return Object.entries(groupedFlights).map(([groupKey, groupedItems]) => ({
        flights: groupedItems,
    }));
};

export const calculateTotalDuration = (segments) => {
    return segments.reduce(
        (total, segmentArray) =>
            total + segmentArray.reduce((segTotal, segment) => segTotal + segment.Duration, 0),
        0
    );
};
export const getTimeSlot = (time) => {
    const hour = new Date(time).getHours();
    if (hour < 12) return 'morning';
    if (hour < 17) return 'noon';
    if (hour < 19) return 'afternoon';
    return 'night';
};

