import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom";
import WithStopComponent from "./flightListcomponents/withStopComponent";
import NonStopComponent from "./flightListcomponents/nonStopComponent";
import {useDispatch} from "react-redux";

const OneWayFlightInfoBox = (props) => {
    let {data, journeyType, title, bookingType = "", sessionId = ""} = props;
    let {Segments} = data;
    let allSegmentData = Segments[0];
    // console.log(title, "title------")
    let checkWhichProcess = journeyType == "2" ? bookingType == title : true;
    const compD = (
        allSegmentData.length > 1 ? <WithStopComponent {...props}/> : <NonStopComponent {...props}/>
    )
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const checkoutInBound = () => {
        navigate(`/checkout`, {state: {params: {bookingType: "Inbound", sessionId}}});
    }

    // console.log(data, "----", sessionId, "idd")

    return (
        <div className={`flight-info-box`}>
            {!checkWhichProcess ? <div className={'disabled-block'}></div> : null}

            {sessionId && title == "Inbound" && !checkWhichProcess ?
                <div className={'book-flight-block'}>
                    <a onClick={checkoutInBound}>Click here to Inbound booking</a>
                </div> : null}

            <div className={`track-flights ${!checkWhichProcess && "opacity-50"}`}>
                {journeyType == "2" ?
                    <div>
                        <div className={'flight-type-box'}>
                            <h6>{title}</h6>
                            <div>
                                {checkWhichProcess ? "In Process" : ""}
                            </div>
                        </div>
                        {compD}
                    </div> :
                    <div>
                        {compD}
                    </div>
                }
            </div>
        </div>
    )
}
export default OneWayFlightInfoBox
