import React, {useEffect} from "react";
import {Outlet, Navigate, useNavigate} from "react-router-dom";
import Header from "./frontLayout/header";
import Footer from "./footer";

const AuthLayout = (props) => {
    let {currentUser} = props;
    const navigate = useNavigate();
    useEffect(() => {
        if (currentUser && currentUser._id) {
            navigate(`/flight-search`)
        }
    }, [])
    return currentUser && currentUser._id ? <>
    </> : (
        <>
            <Header {...props}/>
            <main className="flex-shrink-0">
                <Outlet/>
            </main>
            <Footer/>
        </>
    )
}

export default AuthLayout;
