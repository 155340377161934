import axios from 'axios'
import {apiUrl, apiUrl2} from './settings'


export const getHeader = () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    let token = localStorage.getItem('token');
    let obj = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
        'Access-Control-Allow-Credentials': true,
        "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        'X-Requested-With': 'XMLHttpRequest',
        'crossdomain': true,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
    }
    if (token) {
        obj['Authorization'] = 'Bearer ' + token
    }
    return obj
}

export let customAxios = axios.create({
    baseURL: apiUrl2,
    headers: getHeader()
})
export const authAxios = axios.create({
    baseURL: apiUrl2,
    headers: getHeader()
})
customAxios.interceptors.response.use(function (response) {
    let token = localStorage.getItem('token');
    if (response.status == 200) {

    }
    return response
}, function (error) {
    handleErr(error)
    return Promise.reject(error)
})

export const getUserToken = async () => {
    return new Promise(async (next, error) => {
        try {
            // console.log("token is", await AsyncStorage.getItem('token'))
            next(localStorage.getItem('token'))
        } catch (e) {
            next(null)
        }
    })
}

export const getUserData = async () => {
    return new Promise((next, error) => {
        try {
            next(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null)
        } catch (e) {
            next(null)
        }
    })
}

export const handleErr = (err) => {
    try {
        if (err.response.status === 401) {
            localStorage.clear()
            window.location.reload()
        }
    } catch (e) {
    }
}
export default {}
