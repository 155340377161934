export const loginSendOtpUrl = () => {
    return "/x-travel/login/send-otp"
}
export const loginSubmitLoginUrl = () => {
    return "/x-travel/login/login-by-otp"
}
export const resendOtpUrl = () => {
    return "/x-travel/resend-otp"
}
export const singleUserUrl = () => {
    return '/x-travel/get-user'
}
