import React, {useEffect, useState} from "react"
import FlightImg from "../../../components/flightImg";
import {CabinObject, displayDate, displayTime, minToHours} from "../../../components/utils/appUtils";
import {Popover, Tooltip, Modal} from "../../../components/Elements"
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import AirportImg from '../../../assets/img/airport.png'
import WithStopComponent from "./withStopComponent";
import NonStopComponent from "./nonStopComponent";

const OverseasFlightAddress = (props) => {
    let {data, flightInfo} = props;
    let [selectedFlight, setSelectedFlight] = useState({})
    const [showModal, setShowModal] = useState({
        visible: false,
        searchDestination: '',
        flightDestination: ''
    });
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const events = {
        confirmBookingOld: async (data) => {
            const searchOrigin = flightInfo.origin;
            const searchDestination = flightInfo.destination;
            const flightOrigin = data?.Segments?.[0]?.[0]?.Origin?.Airport?.AirportCode;
            const firstSegment = data?.Segments?.[0];
            const flightDestination = firstSegment?.[firstSegment.length - 1]?.Destination?.Airport?.AirportCode;
            if (
                searchDestination !== flightDestination
            ) {
                setShowModal({
                    visible: true,
                    searchDestination: `${searchOrigin}-${searchDestination}`,
                    flightDestination: `${flightOrigin}-${flightDestination}`,
                });
            } else {
                dispatch({
                    type: "UPDATE_RESULT_INDEX",
                    selectedFlight: {...data, FareBasisCode: {}},
                    traceId: flightInfo.traceId,
                });
                navigate("/checkout");
            }
        },
        confirmBooking: async (data) => {
            const searchOrigin = flightInfo.origin;
            const searchDestination = flightInfo.destination;
            const flightOrigin = data?.Segments?.[0]?.[0]?.Origin?.Airport?.AirportCode;
            const firstSegment = data?.Segments?.[0];
            const flightDestination = firstSegment?.[firstSegment?.length - 1]?.Destination?.Airport?.AirportCode;
            if (searchDestination !== flightDestination) {
                setShowModal({
                    visible: true,
                    searchDestination: `${searchOrigin}-${searchDestination}`,
                    flightDestination: `${flightOrigin}-${flightDestination}`,
                });
            } else {
                dispatch({
                    type: "UPDATE_RESULT_INDEX",
                    selectedFlight: {...data, FareBasisCode: {}},
                    traceId: flightInfo.traceId,
                    flightBookingType: "overseasFlight"
                });
                navigate("/checkout");
            }
        }
    }
    const handleModalAction = (action) => {
        if (action === "yes") {
            setShowModal({...showModal, visible: false});
            dispatch({
                type: "UPDATE_RESULT_INDEX",
                selectedFlight: {...data, FareBasisCode: {}},
                traceId: flightInfo.traceId,
                flightBookingType: "overseasFlight"
            });
            navigate("/checkout");
        } else {
            setShowModal({...showModal, visible: false});
        }
    };
    return (
        data && data['Segments'] && data['Segments']?.length ? <>
            <div className="book-flight mb-3">
                <div className="row row-gap-3 mb-3">
                    <div className="col-lg-10">
                        <div className={''}>
                            <div className="track-flights">
                                {data['Segments'][0]?.length > 1 ?
                                    <WithStopComponent data={data} allSegmentData={data['Segments'][0]}/> :
                                    <NonStopComponent data={data} allSegmentData={data['Segments'][0]}/>}
                            </div>

                            <div className="class-price mt-2">
                                <div className="track-flights">
                                    {data['Segments'][1]?.length > 1 ?
                                        <WithStopComponent data={data} allSegmentData={data['Segments'][1]}/> :
                                        <NonStopComponent data={data} allSegmentData={data['Segments'][1]}/>}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-2">
                        <div className="d-flex flex-wrap gap-3 h-100">
                            <div className="total-price">
                                <span>Total Price</span>
                                <h5>Rs. {data.Fare && data.Fare.PublishedFare ? data.Fare.PublishedFare : ""}
                                </h5>
                                <small></small>
                                <button
                                    onClick={() => {
                                        events.confirmBooking(data)
                                    }}
                                    className="btn btn-primary">Book Now
                                </button>
                            </div>
                            {/*     <button className="btn btn-primary-theme mt-auto w-100"
                                    style={{fontSize: '14px'}}>Change Class
                            </button>*/}
                        </div>
                    </div>
                </div>

            </div>
            {showModal.visible && (
                <Modal
                    visible={showModal.visible}
                    className={'popup-confirm'}
                    width={'38%'}
                    onClose={() => setShowModal({...showModal, visible: false})}
                >
                    <div className={'mt-1 alert-popup'}>
                        <div className={'mb-4'}>
                            <img src={AirportImg} alt={'airportimg'} width={'30%'}/>
                        </div>
                        <h3 className={'mb-4'}>Different Airport Alert</h3>
                        <p className={'mb-4'}>
                            You have searched for Outbound <strong>({showModal.searchDestination})</strong>,
                            however, this flight operates from Outbound <strong>({showModal.flightDestination})</strong>.

                        </p>
                        <h3 className={'mb-4'}>Do you wish to continue?</h3>
                        <div className="d-flex justify-content-center gap-3 mb-2">
                            <button
                                className="customYesBtn"
                                onClick={() => handleModalAction("yes")}
                            >
                                Yes
                            </button>
                            <button
                                className="customNoBtn"
                                onClick={() => handleModalAction("no")}
                            >
                                No
                            </button>

                        </div>
                    </div>
                </Modal>
            )}
        </> : null
    )
}

export default OverseasFlightAddress
