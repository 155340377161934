import React, {useState, useEffect} from "react"
import FlightInfoComponent from "../../checkout/components/FlightInfoComponent";
import {useNavigate} from "react-router-dom";
import {singleTicketFxn, ticketListFxn} from "../../ticketList/action";
import {useDispatch, useSelector} from "react-redux";
import {Card, Tooltip} from "../../../components/Elements";
import moment from "moment";

const ContactDetailsComponent = (props) => {
    const {passengers, ticketState} = props;
    // console.log(passengers, "passengerss---", ticketState, "siglle")
    const getPassengerDetails = (passenger) => {
        if (passenger) {
            const {
                FirstName: firstName,
                LastName: lastName,
                Gender: gender,
                DateOfBirth: dateOfBirth,
                ContactNo,
                Email
            } = passenger;
            return (
                <Card className={"mb-2"}>
                    <div className={'row'}>
                        <div className={'col-md-4'}>
                            <b>Name</b>: {firstName && lastName ? `${firstName} ${lastName}` : ""}<br/>
                            <b>Gender</b>: {gender === 2 ? "Female" : "Male"}<br/>
                            <b>D.O.B</b>: {moment(dateOfBirth).format("DD-MMM-YYYY")}<br/>
                        </div>
                        <div className={'col-md-5'}>
                            {ContactNo ? <>
                                <b>Contact No</b>: {ContactNo}<br/>
                            </> : null}
                            {Email ? <>
                                <b>Email ID</b>: {Email}<br/>
                            </> : null}
                        </div>
                        <div className={'col-md-3'}>

                            <a
                                href={`/ticket-details?ticketId=${ticketState._id}&firstName=${firstName}&lastName=${lastName}`}
                                target="_blank"
                                className={'passengersStyle'}>
                                <small className="d-block">
                                    <Tooltip
                                        title={`View Ticket - ${firstName} ${lastName}`}>
                                        <button className={'customYesBtn'}>View Ticket</button>
                                    </Tooltip>
                                </small>
                            </a>
                        </div>

                    </div>
                </Card>
            );
        }
    };

    return (
        <>
            <Card title={'Contact Details'} round={12}>
                {passengers && passengers.length ? passengers.map((item) => {
                    return getPassengerDetails(item)
                }) : null}
            </Card>
        </>
    );
};
const Success = (props) => {
    let {response, ticketId, sessionId, passengers, bookingType} = props;
    const [ticketState, setTicketState] = useState({});
    let navigate = useNavigate()
    let dispatch = useDispatch()
    const {flightObj, traceId, selectedFlight, fareQuoteObj} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj,
        selectedFlight: state.flightsReducer.selectedFlight,
        traceId: state.flightsReducer.traceId,
        fareQuoteObj: state.flightsReducer.fareQuoteObj
    }))

    console.log(response, "resp", passengers, "passengers-----")
    const ShowInvoice = () => {
        navigate(`/booking-details?ticketId=${ticketId}`)
    }

    const events = {
        singleTicket: async () => {
            let {data} = await dispatch(singleTicketFxn({ticketId: ticketId}))
            // console.log(data, "data");
            setTicketState(data);
        },
    };

    useEffect(() => {
        events.singleTicket();
    }, []);

    return (
        <section className="agent-with-us">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-9">
                        {sessionId ?


                            <FlightInfoComponent
                                page={'response'}
                                flightObj={flightObj}
                                selectedFlight={selectedFlight} traceId={traceId}
                                sessionId={sessionId}
                                bookingType={bookingType}/> : null}


                        <div className="head text-start">
                            <div className="card mt-4 mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">Booking Details</h5>
                                    <div className="row">

                                        <div className="col-md-5">
                                            <p>Unizportal</p>
                                            <hr/>
                                            <p><strong>Address:</strong> New Delhi</p>
                                            <p><strong>Phone:</strong> 4555221111</p>
                                        </div>

                                        <div className="col-md-5">
                                            <p><strong>PNR No.:</strong> {response?.response?.PNR || "N/A"}</p>
                                            <p><strong>Booked
                                                Date:</strong> {new Date(response?.response?.FlightItinerary?.InvoiceCreatedOn).toLocaleDateString() || "N/A"}
                                            </p>
                                            <p><strong>Travel
                                                Date:</strong> {new Date(response?.response?.FlightItinerary?.Segments?.[0]?.Origin?.DepTime).toLocaleDateString() || "N/A"}
                                            </p>
                                        </div>

                                                <div className="col-md-2">
                                                    <a className="btn btn-primary text-nowrap mt-auto"
                                                       href={`/booking-details?ticketId=${ticketId}`}
                                                       target="_blank">
                                                        View Invoice
                                                    </a>
                                                </div>
                                    </div>

                                    <div className="mt-3">
                                        <label className="btn mt-2 ticketBtn">
                                            Status: {response?.response?.TicketStatus === 1 ? "Ticketed" : "Hold"}
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="flight-accordion-details mt-3">
                            <ContactDetailsComponent
                                passengers={passengers}
                                ticketState={ticketState}
                            />
                        </div>

                        <div className="flight-accordion-details mt-3">
                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header d-flex align-items-center"
                                        id="panelsStayOpen-headingOne">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link active"
                                                    id="fare-rules-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#fare-rules-tab-pane"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="fare-rules-tab-pane"
                                                    aria-selected="true"
                                                >
                                                    Fare Rules
                                                </button>
                                            </li>
                                        </ul>

                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="panelsStayOpen-headingOne"
                                    >

                                        <div className="accordion-body">
                                            <div className="tab-content" id="myTabContent">
                                                <div
                                                    className="tab-pane fade active show"
                                                    id="fare-rules-tab-pane"
                                                    role="tabpanel"
                                                    aria-labelledby="fare-rules-tab"
                                                    tabIndex="0"
                                                >
                                                    <div className="flight-custom-table">
                                                        <div className="content-box"
                                                             style={{maxHeight: "400px", overflowY: "scroll"}}>
                                                            <ul>
                                                                <small
                                                                    style={{fontSize: "14px", fontWeight: "4px"}}
                                                                    className="mb-3 d-block"
                                                                >
                                                                    * To view charges, see the fee sections below.
                                                                    {response?.response?.FlightItinerary?.FareRules?.map((rule, index) => (
                                                                        <div
                                                                            key={index}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: rule.FareRuleDetail.replace(/\n/g, "<br />")
                                                                            }}
                                                                            style={{listStyleType: "disc"}}
                                                                        />
                                                                    ))}
                                                                </small>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Success
