import _ from "lodash";
import {Input, notification, PhoneNo} from "../../../components/Elements";
import React, {useEffect, useState} from "react";
import {daysArr, genderArr, monthArr, nationalityArr, titleArr, yearArr} from "../../../components/utils/appUtils";
import {CustomFormFields} from "../../../components/utils/customFormUtils";
import ArrowUp from '../../../assets/img/arrowUp.png'
import AncillaryServiceForm from "./addAncillaryForm";
import {useLocation} from "react-router-dom";
import {ticketBySessionIdFxn} from "../../flightSearch/actions";
import {useDispatch} from "react-redux";
import moment from "moment";
import SeatPreferences from "../../ModalComponent/seatPreferences";
import countryJson from '../../../components/Elements/PhoneInput/countryFlag.json'
import {FlightSummary} from "./summary";

let initState = {
    title: undefined,
    firstName: "",
    gender: undefined,
    day: undefined,
    month: undefined,
    year: undefined,
    Nationality: "",
    PassportNo: "",
    PassportExpiry: "",
    PassportIssueCountry: "",
    PassportIssueDate: "",
    Baggage: [],
    MealDynamic: [],
    SeatDynamic: [],
    BagDynamic: {},
    SpecialServices: [],
    PassportIssueCountryCode: "",
    CellCountryCode: "",
}

let initGstState = {
    "gstCompanyAddress": "",
    "gstCompanyContactNumber": "",
    "gstCompanyName": "",
    "gstNumber": "",
    "gstCompanyEmail": ""
}

const contactInitState = {
    email: "",
    contactNo: ""
}

class SinglePassengerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.data,
        };
    }

    componentDidUpdate(prevProps) {

        if (prevProps.data !== this.props.data) {
            this.setState({ ...this.props.data });
        }
    }

    passEvents = {
        _updateState: (obj) => {
            this.setState({ ...obj }, () => {
                this.props.onChange(this.state);
            });
        },
        onChange: (dataObj, fieldName) => {
            if (fieldName === "title") {
                let findGender = _.find(titleArr, (item) => item.name === dataObj["title"]);
                if (findGender) {
                    dataObj["gender"] = findGender.gender;
                }
            } else if (fieldName === "PassportIssueCountry") {
                let selectedCountry = _.find(countryJson, (item) => item.name === dataObj["PassportIssueCountry"]);
                if (selectedCountry) {
                    dataObj["PassportIssueCountryCode"] = selectedCountry.isoCode;
                    dataObj["CellCountryCode"] = selectedCountry.dialCode;
                }
            }
            this.passEvents._updateState(dataObj);
        },
    };



    render() {
        let {fareObject, passengerType, index, bookingType} = this.props;
        let {state} = this;

        const passengerField = [
            {
                key: "title",
                type: "select",
                placeholder: "Choose Title *",
                required: true,
                options: titleArr,
                span: "col-md-2",
                keyAccessor: (option) => option.name,
                valueAccessor: (option) => option.name,
            },
            {
                key: "firstName",
                placeholder: "First Name *",
                required: true,
                span: "col-md-5",
            },
            {
                key: "lastName",
                placeholder: "Last Name *",
                required: true,
                span: "col-md-5",
            },
            {
                key: "gender",
                type: "select",
                placeholder: "Gender *",
                required: true,
                options: genderArr,
                span: "col-md-6",
                keyAccessor: (option) => option.value,
                valueAccessor: (option) => option.name,
            },
            {
                key: "day",
                type: "select",
                placeholder: "Day *",
                required: true,
                span: "col-md-2",
                showSearch: true,
                options: daysArr(),
            },
            {
                key: "month",
                type: "select",
                placeholder: "Month *",
                required: true,
                span: "col-md-2",
                showSearch: false,
                options: monthArr,
                keyAccessor: (option) => option.key,
                valueAccessor: (option) => option.name,
            },
            {
                key: "year",
                type: "select",
                placeholder: "Year *",
                required: true,
                showSearch: true,
                span: "col-md-2",
                options: yearArr(passengerType),
            },
            {
                key: "Nationality",
                placeholder: "Nationality",
                type: "select",
                required: true,
                span: "col-md-6",
                options: nationalityArr,
                showSearch: true,
                keyAccessor: (option) => option.nationality,
                valueAccessor: (option) => option.nationality,
                hidden: !(fareObject && fareObject['IsPassportRequiredAtTicket'])
            },
            {
                key: "PassportNo",
                placeholder: "Passport Number",
                type: "text",
                required: true,
                span: "col-md-6",
                hidden: !(fareObject && fareObject['IsPassportRequiredAtTicket'])
            },
            {
                key: "PassportExpiry",
                type: "dateOfBirth",
                placeholder: "Expiry Date",
                required: true,
                span: "col-md-6",
                hidden: !(fareObject && fareObject['IsPassportRequiredAtTicket'])
                // format: "YYYY-MM-DD",
            },
            {
                key: "PassportIssueCountry",
                placeholder: "Passport Country",
                type: "select",
                required: true,
                span: "col-md-6",
                options: countryJson,
                showSearch: true,
                keyAccessor: (option) => option.name,
                valueAccessor: (option) => option.name,
                hidden: !(fareObject && fareObject['IsPassportRequiredAtTicket'])

            },
            {
                key: "PassportIssueDate",
                type: "dateOfBirth",
                placeholder: "Passport Issue Date",
                required: true,
                span: "col-md-6",
                hidden: !(fareObject && fareObject['IsPassportRequiredAtTicket'])
                // format: "YYYY-MM-DD",
            }
        ];
        let customId = `${passengerType}-${index + 1}`;
        // console.log(state, "00000000")
        return (
            <div className="accordion-item" key={customId}>
                <h2 className="accordion-header" id={`${customId}-headingOne`}>
                    <ul>
                        <li className="p-3 head-accordion">
                            <h6 className={"text-capitalize"}>
                                {passengerType} {index + 1}
                            </h6>
                            <a
                                href="#"
                                data-bs-toggle="collapse"
                                data-bs-target={`#${customId}-collapseOne`}
                                aria-expanded="true"
                                aria-controls={`${customId}-collapseOne`}
                                className="btn p-0 collapsed"
                            >
                                <img src={ArrowUp} alt="arrow-up" width={28}/>
                            </a>
                        </li>
                    </ul>
                </h2>
                <div
                    id={`${customId}-collapseOne`}
                    className="accordion-collapse collapse show"
                    aria-labelledby={`${customId}-headingOne`}>
                    <div className="accordion-body">
                        <div className="row row-gap-3">
                            {passengerField.map((item) => {
                                let id = `${item.key}-${index}-${passengerType}`;
                                return (
                                    !item.hidden ? <div className={item.span} key={id}>
                                        <CustomFormFields
                                            item={{
                                                size: 'small',
                                                ...item, passengerType: passengerType,
                                                required: item.required,
                                                id: id, name: id,
                                                disabled: bookingType == "Inbound",
                                                value: state && state[item.key] ? state[item.key] : "",
                                                onChange: (value) => {
                                                    this.passEvents.onChange({[item.key]: value}, item.key)
                                                }
                                            }}/>
                                    </div> : null
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}


const Passengers = (props) => {
    let {flightObj, fareObject, callbackState, traceId, selectedFlight, bookingType} = props;

    const [childPassengers, setChildPassengers] = useState([]);
    const [adultPassengers, setAdultPassengers] = useState([]);
    const [infantPassengers, setInfantPassengers] = useState([]);
    const [state, setState] = useState(contactInitState);
    let [gstState, setGstState] = useState(initGstState);
    const [ancillaryData, setAncillaryData] = useState(null);
    const [showAncillaryModal, setShowAncillaryModal] = useState(false);
    const location = useLocation();
    const stateParams = location.state?.params;
    const dispatch = useDispatch();
    useEffect(() => {
        events.loadInvoiceData()
    }, [stateParams, traceId, bookingType])
    // console.log(bookingType, "typpe")
    useEffect(() => {
        events.initPassenger()
    }, [])

    const gstFields = [
        {
            key: "gstNumber",
            placeholder: "GST Number",
            required: true,
            span: "col-md-6",
        },
        {
            key: "gstCompanyName",
            placeholder: "Company Name",
            required: true,
            span: "col-md-6",
        },
        {
            key: "gstCompanyAddress",
            placeholder: " Company Address",
            required: true,
            span: "col-md-6",
        },
        {
            key: "gstCompanyContactNumber",
            placeholder: "Company Contact Number",
            required: true,
            span: "col-md-6",
        },
        {
            key: "gstCompanyEmail",
            placeholder: "Company Email",
            required: true,
            span: "col-md-6",
        },
    ]

    const events = {
        initPassenger: () => {
            let adultPassengers = []
            let childPassengers = []
            let infantPassengers = []
            _.times(flightObj.adultCount, (item) => {
                adultPassengers.push(initState)
            })
            _.times(flightObj.childCount, (item) => {
                childPassengers.push(initState)
            })
            _.times(flightObj.infantCount, (item) => {
                infantPassengers.push(initState)
            })
            setAdultPassengers(adultPassengers);
            setChildPassengers(childPassengers);
            setInfantPassengers(infantPassengers);
        },
        updateAdultPassenger: (data, index) => {

            setAdultPassengers((prevPassengers) => {
                let cloneD = _.cloneDeep(prevPassengers);

                cloneD[index] = {
                    ...cloneD[index],
                    ...data,
                    // SeatDynamic: data.SeatDynamic !== undefined ? data.SeatDynamic : cloneD[index]?.SeatDynamic || []
                };
                return [...cloneD];
            });
        },

        updateChildPassenger: (data, index) => {
            setChildPassengers((prevPassengers) => {
                let cloneD = _.cloneDeep(prevPassengers);

                cloneD[index] = {
                    ...cloneD[index],
                    ...data,
                    // SeatDynamic: data.SeatDynamic !== undefined ? data.SeatDynamic : cloneD[index]?.SeatDynamic || []
                };

                return [...cloneD];
            });
        },

        updateInfantPassengers: (data, index) => {
            let cloneD = _.clone(infantPassengers);
            cloneD[index] = {...cloneD[index], ...data}
            setInfantPassengers(cloneD)
        },
        updateGstState: (data) => {
            setGstState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        sendForReview: (e, overrideAncillaryCheck = false) => {
            if (e) e.preventDefault();

            // if (!ancillaryData && !overrideAncillaryCheck) {
            //     setShowAncillaryModal(true);
            //     return;
            // }
            let allPassenger = [...adultPassengers, ...childPassengers, ...infantPassengers];
            // console.log(allPassenger, "allll-----")
            let ancillaryEmpty = allPassenger.every(passenger =>
                _.isEmpty(passenger.Baggage) &&
                _.isEmpty(passenger.MealDynamic) &&
                _.isEmpty(passenger.SeatDynamic) &&
                _.isEmpty(passenger.BagDynamic) &&
                _.isEmpty(passenger.SpecialServices)
            );

            if (ancillaryEmpty && !overrideAncillaryCheck) {
                setShowAncillaryModal(true);
                return;
            }
            let findE = _.find(allPassenger, (item) => {
                return !item.title || !item.firstName || !item.lastName || !item.gender || !item.day || !item.month || !item.year;
            });

            if (findE) {
                // console.log(findE);
                notification.error({message: `Please enter all required fields. Passenger - ${findE.firstName} ${findE.lastName}`});
                return;
            }

            allPassenger.forEach((passenger, index) => {
                if (!passenger.firstName || passenger.firstName.length < 2) {
                    notification.error({message: `First Name for Passenger ${index + 1} must be at least 2 characters long.`});
                    return;
                }
                if (!passenger.lastName || passenger.lastName.length < 2) {
                    notification.error({message: `Last Name for Passenger ${index + 1} must be at least 2 characters long.`});
                    return;
                }
            });

            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!state.email || !emailPattern.test(state.email)) {
                notification.error({message: "Please enter a valid email address."});
                return;
            }

            if (!state.contactNo) {
                notification.error({message: "Please enter contact no."});
                return;
            }

            /*            allPassenger?.forEach((passenger, index) => {
                            const ancillaryService = ancillaryData?.find((ancillary) => {
                                return ancillary.firstName === passenger.firstName && ancillary.lastName === passenger.lastName;
                            });

                            if (ancillaryService) {
                                passenger.Baggage = Array.isArray(ancillaryService?.ancillaryService?.baggage)
                                    ? ancillaryService?.ancillaryService?.baggage
                                    : [ancillaryService?.ancillaryService?.baggage];

                                passenger.MealDynamic = Array.isArray(ancillaryService?.ancillaryService?.meal)
                                    ? ancillaryService?.ancillaryService?.meal
                                    : [ancillaryService?.ancillaryService?.meal];

                                passenger.SeatDynamic = Array.isArray(ancillaryService?.ancillaryService?.seat)
                                    ? ancillaryService?.ancillaryService?.seat
                                    : [ancillaryService?.ancillaryService?.seat];

                                passenger.SpecialServices = ancillaryService?.ancillaryService?.specialServices;
                            }
                        });*/

            // setAdultPassengers(allPassenger.filter((passenger) => passenger.gender === 2));
            // setChildPassengers(allPassenger.filter((passenger) => passenger.gender === 1));
            // setInfantPassengers(allPassenger.filter((passenger) => passenger.gender === 0));


            callbackState({
                childPassengers,
                adultPassengers,
                infantPassengers,
                state: state,
                gstState,
            });
        },
        loadInvoiceData: async () => {
             if (stateParams && stateParams.sessionId) {
                 let {success, data} = await dispatch(ticketBySessionIdFxn({sessionId: stateParams.sessionId}));
                // console.log(data, "Datattt")
                 if (success) {
                     let passengers = data['Passengers'];
                     let contactInfo = {}
                     let gstInfo = {}
                     let adultPass = []
                     let childPass = []
                     let infantPass = []

                     _.each(passengers, (item) => {
                         let obj = {}
                         obj.title = item['Title']
                         obj.firstName = item['FirstName']
                         obj.lastName = item['LastName']
                         obj.gender = item['Gender']
                         if (item['DateOfBirth']) {
                             obj.day = moment(item['DateOfBirth']).date();
                             obj.month = moment(item['DateOfBirth']).month() + 1;
                             obj.year = moment(item['DateOfBirth']).year();
                         }
                         if (!contactInfo.email) {
                             contactInfo['email'] = item['Email']
                         }
                         if (!contactInfo.contactNo) {
                             contactInfo['contactNo'] = item['ContactNo']
                         }
                         if (!gstInfo.gstNumber) {
                             gstInfo['gstNumber'] = item['GSTNumber'];
                         }
                         if (!gstInfo.gstCompanyName) {
                             gstInfo['gstCompanyName'] = item['GSTCompanyName'];
                         }
                         if (!gstInfo.gstCompanyAddress) {
                             gstInfo['gstCompanyAddress'] = item['GSTCompanyAddress'];
                         }
                         if (!gstInfo.gstCompanyContactNumber) {
                             gstInfo['gstCompanyContactNumber'] = item['GSTCompanyContactNumber'];
                         }
                         if (!gstInfo.gstCompanyEmail) {
                             gstInfo['gstCompanyEmail'] = item['GSTCompanyEmail'];
                         }
                         obj.Baggage = [];
                         obj.MealDynamic = [];
                         obj.SeatDynamic = [];
                         obj.BagDynamic = {};
                         obj.SpecialServices = [];


                        if (item['PaxType'] == 1) {
                            adultPass.push(obj);
                        }
                        if (item['PaxType'] == 2) {
                            childPass.push(obj);
                        }
                        if (item['PaxType'] == 3) {
                            infantPass.push(obj);
                        }
                    })


                     setAdultPassengers(adultPass)
                     setChildPassengers(childPass)
                     setInfantPassengers(infantPass)
                     events._updateState(contactInfo);
                     events.updateGstState(gstInfo);
                }
            }
        },


        chooseAncillaryService: (data, type, index) => {
            // console.log(data, "----", type, "----", index, "-----")
            if (type == "child") {
                events.updateChildPassenger(data, index)
            } else {
                events.updateAdultPassenger(data, index)
            }
            // events.sendForReview(null, false)
        }
    }

    const handleProceed = () => {
        setShowAncillaryModal(false);
        events.sendForReview(null, true);
    };

    return (
        <>
            <form onSubmit={events.sendForReview}>
                <div className="passanger-details">
                    <div className={"track-flights"} style={{overFlow: 'hidden'}}>
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div>
                                {adultPassengers && adultPassengers.length ? adultPassengers.map((item, adItem) => {
                                    return (
                                        <SinglePassengerComponent
                                            data={item}
                                            fareObject={fareObject}
                                            index={adItem}
                                            bookingType={bookingType}
                                            adultPassengers={adultPassengers}
                                            onChange={(data) => {
                                                events.updateAdultPassenger(data, adItem)
                                            }}
                                            passengerType={'adult'} key={`adult-${adItem}`}/>
                                    )
                                }) : null}
                            </div>
                            <div>
                                {childPassengers && childPassengers.length ? childPassengers.map((item, adItem) => {
                                    return (
                                        <SinglePassengerComponent
                                            data={item}
                                            fareObject={fareObject}
                                            childPassengers={childPassengers}
                                            index={adItem}
                                            bookingType={bookingType}
                                            onChange={(data) => {
                                                events.updateChildPassenger(data, adItem)
                                            }}
                                            passengerType={'child'} key={`child-${adItem}`}/>
                                    )
                                }) : null}
                            </div>
                            <div>
                                {infantPassengers && infantPassengers.length ? infantPassengers.map((item, adItem) => {
                                    return (
                                        <SinglePassengerComponent
                                            data={item}
                                            infantPassengers={infantPassengers}
                                            fareObject={fareObject}
                                            index={adItem}
                                            bookingType={bookingType}
                                            onChange={(data) => {
                                                events.updateInfantPassengers(data, adItem)
                                            }}
                                            passengerType={'infant'} key={`infant-${adItem}`}/>
                                    )
                                }) : null}
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                    <ul>
                                        <li className="p-3 head-accordion">
                                            <h6>Contact Details</h6>
                                            <div className="d-flex gap-3">

                                                <a
                                                    href=""
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                    className="btn p-0 collapsed"
                                                >
                                                    <img src={ArrowUp}
                                                         alt="arrow-up" width={28}/>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseTwo"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="panelsStayOpen-headingTwo">
                                    <div className="accordion-body">

                                        <div className="row row-gap-3">
                                            <div className="col-lg-6">
                                                <PhoneNo
                                                    type={'number'}
                                                    size={'small'}
                                                    placeholder={'Contact No'}
                                                    required={true}
                                                    disabled={bookingType == "Inbound"}
                                                    value={state.contactNo}
                                                    onChange={(value) => {
                                                        events._updateState({contactNo: value})
                                                    }}
                                                />
                                            </div>

                                            <div className="col-lg-6">
                                                <Input
                                                    type={'text'}
                                                    placeholder={'Email address'}
                                                    value={state.email}
                                                    size={'small'}
                                                    required={true}
                                                    disabled={bookingType == "Inbound"}
                                                    onChange={(value) => {
                                                        events._updateState({email: value})
                                                    }}
                                                />

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                    <ul>
                                        <li className="p-3 head-accordion">
                                            <h6>Ancillary Services</h6>
                                            <div className="d-flex gap-3">

                                                <a
                                                    href=""
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                    className="btn p-0 collapsed"
                                                >
                                                    <img src={ArrowUp}
                                                         alt="arrow-up" width={28}/>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseThree"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="panelsStayOpen-headingThree">
                                    <div className="accordion-body">

                                        <AncillaryServiceForm
                                            fareObj={fareObject}
                                            adultPassengers={adultPassengers}
                                            childPassengers={childPassengers}
                                            infantPassengers={infantPassengers}
                                            selectedFlight={selectedFlight}
                                            bookingType={bookingType}
                                            updateAncillaryServices={events.chooseAncillaryService}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                    <ul>
                                        <li className="p-3 head-accordion">
                                            <h6>GST Number for Business Travel (Optional)</h6>
                                            <div className="d-flex gap-3">
                                                <a className="btn bg-transparent p-0 active">
                                                    Save GST Details
                                                </a>
                                                <a
                                                    href=""
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                    className="btn p-0 collapsed"
                                                >
                                                    <img src={ArrowUp} alt="arrow-up" width={28}/>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="panelsStayOpen-headingThree"
                                >
                                    <div className="accordion-body">
                                        <form>
                                            <div className="row row-gap-3">
                                                {gstFields.map((item) => {
                                                    return (
                                                        !item.hidden ? <div className={item.span} key={item.key}>
                                                            <CustomFormFields
                                                                item={{
                                                                    size: 'small',
                                                                    ...item,
                                                                    value: gstState && gstState[item.key] ? gstState[item.key] : "",
                                                                    disabled: bookingType === "Inbound",
                                                                    onChange: (value) => {
                                                                        events.updateGstState({[item.key]: value})
                                                                    }
                                                                }}/>
                                                        </div> : null
                                                    );
                                                })}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <button className="btn btn-primary w-100 mt-3">PROCEED TO REVIEW</button>
                {showAncillaryModal && (
                    <SeatPreferences
                        visible={showAncillaryModal}
                        onClose={() => setShowAncillaryModal(false)}
                        onProceed={handleProceed}
                    />
                )}
            </form>
            {/*{fareObject && (*/}
            {/*    <FlightSummary flightData={fareObject}*/}
            {/*                   adultPassengers={adultPassengers}*/}
            {/*                   childPassengers={childPassengers}*/}
            {/*    />*/}
            {/*)}*/}
        </>
    )
}
export default Passengers
