import React, {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {listAllTransferFunctionFxn2} from "../flightSearch/actions";
import moment from "moment";
import {Table} from "../../components/Elements";
import {displayDate} from "../../components/utils/appUtils";

const DepositList = () => {
    let userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    const dispatch = useDispatch();
    let tableRef = useRef()
    let initialFilter = {date: "", agent: "", transactionType: ""}
    const [filters, setFilters] = useState(initialFilter);
    const [totalItems, setTotalItems] = useState(0);
    const [creditTotal, setCreditTotal] = useState(0);
    const [debitTotal, setDebitTotal] = useState(0);

    const apiRequest = async (params) => {
        return new Promise(async (resolve) => {
            if (filters.date) {
                params.date = {
                    $gte: moment(filters.date).startOf('day').toDate(),
                    $lte: moment(filters.date).endOf('day').toDate()
                };
            }
            if (filters.agent) {
                params.agentName = filters.agent
            }
            if (filters.transactionType) {
                params.transactionType = filters.transactionType
            }

            let resp = await dispatch(listAllTransferFunctionFxn2({
                ...params,
                regExFilters: ['agentName']
            }))
            setTotalItems(resp.total);
            setCreditTotal(Number(resp.creditTotal));
            setDebitTotal(Number(resp.debitTotal));
            resolve(resp)
        })

    }


    const handleFilterChange = (e) => {
        const {name, value} = e.target;
        setFilters((prev) => ({...prev, [name]: value}));
    };

    const applyFilters = () => {
        tableRef.current.reload()
    };
    const clearFilters = () => {
        setFilters(initialFilter)
        setTimeout(() => {
            tableRef.current.reload()
        }, 200)
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 100,
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 150,
            render: item => {
                return item ? displayDate(item) : ''
            }
        },
        {
            title: 'Description',
            dataIndex: 'note',
            key: 'note',
            width: 200,
        },
        {
            title: 'Agent Name',
            dataIndex: 'agentName',
            key: 'agentName',
            width: 150,
            hidden: userData.userType && userData.userType != 'admin',
            render: (item, record) => (
                <span>{item}<br/>({record.agentCompanyName && record.agentCompanyName})</span>
            )
        },
        {
            title: 'Credit',
            dataIndex: 'amount',
            key: 'credit',
            width: 150,
            render: (amount, record) =>
                record.transactionType === "Credit" && (
                    <div className={"text-success fw-bold"}>
                        ₹ {amount}
                    </div>
                )
        },
        {
            title: 'Debit',
            dataIndex: 'amount',
            key: 'debit',
            width: 150,
            render: (amount, record) =>
                record.transactionType === "Debit" && (
                    <div className={"text-danger fw-bold"}>
                        ₹ {amount}
                    </div>
                )
        }

    ]

    return (
        <div className="row">
            <div className="col-lg-12">
                <h5 className="mb-4">Deposit List({totalItems ? totalItems : 0})</h5>
                <div className="mb-4 col-12 d-flex">
                    <div className={"col-3 ms-2 mb-2"}>
                        <input
                            type="date"
                            name="date"
                            className="form-control font12"
                            value={filters.date}
                            onChange={handleFilterChange}
                            placeholder="Filter by Date"
                        />
                    </div>

                    {
                        userData && userData.userType && userData.userType == 'admin' &&
                        <div className={"col-3 ms-2 mb-2"}>
                            <input
                                type="text"
                                name="agent"
                                className="form-control font12"
                                value={filters.agent}
                                onChange={handleFilterChange}
                                placeholder="Filter by Agent Name"
                            />
                        </div>
                    }

                    <div className={"col-3 ms-2 mb-2"}>
                        <select
                            name="transactionType"
                            className="form-control font12"
                            value={filters.transactionType}
                            onChange={handleFilterChange}
                        >
                            <option value="">Transaction Type (Credit & Debit)</option>
                            <option value="Credit">Credit</option>
                            <option value="Debit">Debit</option>
                        </select>
                    </div>
                    <div className={"col-3 ms-2 mb-2"}>
                        <button className="btn btn-primary ms-2 font12" onClick={applyFilters}>
                            Apply Filters
                        </button>
                        <button className="btn btn-primary ms-2 font12" onClick={clearFilters}>
                            Clear Filters
                        </button>
                    </div>
                </div>

                {
                    userData.userType && userData.userType != 'admin' &&
                    <div className="row col-12 mb10">
                        <div className="col-md-4 ">
                            <div className="card shadow-sm">
                                <div className="card-body text-center font12">
                                    <h6>Total Credit</h6>
                                    <span className="text-success fw-bold">₹ {creditTotal}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center font12">
                                    <h6>Total Debit</h6>
                                    <span className="text-danger fw-bold">₹ {debitTotal}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center font12">
                                    <h6>Balance</h6>
                                    <span
                                        className={creditTotal - debitTotal >= 0 ? "text-success fw-bold" : "text-danger fw-bold"}>
                                         ₹ {creditTotal - debitTotal}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <Table
                    columns={columns}
                    apiRequest={apiRequest}
                    ref={tableRef}
                    defaultSortOrder='descend'
                />
            </div>
        </div>
    );
};

export default DepositList;
