import React from "react";
import moment from "moment";
import OverseasFlightInfoBox from "../../flightSearchResult/overseasFlightInfoBox";

const flightDate = (date) => {
    return moment(date).format("dddd, MMMM, Do, YYYY")
}

const FlightDestination = (props) => {
    let {flightObj} = props;
    return (
        <>
            <div className="flight-destimation-time">
                <div className="row row-gap-3 align-items-center">
                    <div className="col-lg-4">
                        <div className="d-flex align-items-center"
                             style={{gap: '30px'}}>
                            <h6 className="m-0 white">{flightObj.origin}</h6>
                            <span><img src="../images2/plane.png" width="22"
                                       alt="plane"/></span>
                            <h6 className="m-0 white">{flightObj.destination}</h6>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <p className="m-0">on {flightDate(flightObj.preferredDepartureTime)}</p>
                    </div>
                    <div className="col-lg-4">
                        <p className="m-0">21hr 10min</p>
                    </div>
                </div>
            </div>

        </>
    )
}

const OverseasFlightInfoComponent = (props) => {
    let {flightObj, selectedFlight, bookingType = '', sessionId, flightBookingType} = props;

    return (
        <>
            <div className={'mb-4 find-flight-main'}>
                <FlightDestination flightObj={flightObj}/>
                <div className={'accordion-flight-track'}>
                    <div className={'row'}>
                        {selectedFlight['Segments'] ? (
                            <>
                                {selectedFlight['Segments'] && selectedFlight['Segments'][0] ?
                                    <div className={'col-md-12'}>
                                        <OverseasFlightInfoBox
                                            // title={'Outbound'}
                                            journeyType={flightObj.journeyType}
                                            allSegmentData={selectedFlight['Segments'][0]}
                                            data={selectedFlight}
                                            bookingType={bookingType}
                                        />
                                    </div> : null}

                                {/*{selectedFlight['Segments'] && selectedFlight['Segments'][1] ?*/}
                                {/*    <div className={'col-md-6'}>*/}
                                {/*        <OverseasFlightInfoBox*/}
                                {/*            title={'Inbound'}*/}
                                {/*            sessionId={sessionId}*/}
                                {/*            allSegmentData={selectedFlight['Segments'][1]}*/}
                                {/*            journeyType={flightObj.journeyType}*/}
                                {/*            data={selectedFlight}*/}
                                {/*            bookingType={bookingType}*/}
                                {/*        />*/}
                                {/*    </div> : null}*/}
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OverseasFlightInfoComponent