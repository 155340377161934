import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {minToHours} from "../../../components/utils/appUtils";
import {
    calculateCheapestAndFastestFlights,
    groupFlightsFxn,
    calculateTotalDuration,
} from "../commonFilterFxns";
import _ from "lodash"

const SortFlightFilters = (props) => {
    const {flightList, refundFilterList, setSortedFlights} = props
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selectedOption, setSelectedOption] = useState('');

    const [sortDirection, setSortDirection] = useState('asc');

    const [flightNumber, setFlightNumber] = useState('');
    const {
        cheapestFlight,
        cheapestFlightDuration,
        fastestFlight,
        fastestFlightDuration,
        cheapestFlightDetails,
        fastestFlightDetails
    } = calculateCheapestAndFastestFlights(flightList);


    const handleSortChange = (criteria) => {
        const newSortDirection = selectedOption === criteria ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
        setSortDirection(newSortDirection);
        setSelectedOption(criteria);

        let sorted = [];


        if (criteria === 'flightNumber' && flightNumber) {
            sorted = flightList.filter(flight =>
                flight.Segments[0][0].Airline.FlightNumber.toString().includes(flightNumber)
            );
        } else {

            sorted = [...flightList].sort((a, b) => {
                let comparison = 0;

                switch (criteria) {
                    case 'price':
                        comparison = a.Fare.PublishedFare - b.Fare.PublishedFare;
                        break;
                    case 'duration':
                        const aDuration = calculateTotalDuration(a.Segments);
                        const bDuration = calculateTotalDuration(b.Segments);
                        comparison = aDuration - bDuration;
                        break;
                    case 'arrival':
                        const aArrivalTime = new Date(a.Segments[0][0].Destination.ArrTime);
                        const bArrivalTime = new Date(b.Segments[0][0].Destination.ArrTime);
                        comparison = aArrivalTime - bArrivalTime;
                        break;
                    case 'stops':
                        comparison = a.Segments[0].length - b.Segments[0].length;
                        break;
                    // case 'flightNumber':
                    //     comparison = a.Segments[0][0].Airline.FlightNumber.localeCompare(b.Segments[0][0].Airline.FlightNumber);
                    //     break;
                    default:
                        break;
                }

                return newSortDirection === 'asc' ? comparison : -comparison;
            });
        }

        setSortedFlights(sorted);
    };


    const handleChange = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        if (checked) {
            setSelectedOption(value)
        } else {
            setSelectedOption("")
        }
        /*if (value === 'cheapest') {
            setSortedFlights([cheapestFlightDetails]);
        } else if (value === 'fastest') {
            setSortedFlights([fastestFlightDetails]);
        }*/
    };

    const events = {
        groupFlights: async (sortedFlights) => {
            let resp = await groupFlightsFxn(sortedFlights)
            setSortedFlights(resp)
            // console.log(resp, "uuuuuuuuuuu")
        }
    }


    const filterFlights = () => {
        const filtered = _.filter(flightList, (flight) => {
            let filterArr = []


            if (selectedOption) {
                if (selectedOption == "cheapest") {
                    filterArr.push(flight['ResultIndex'] == cheapestFlightDetails['ResultIndex'])
                }
                if (selectedOption == "fastest") {
                    filterArr.push(flight['ResultIndex'] == fastestFlightDetails['ResultIndex'])
                }
            }


            return filterArr.every(Boolean);
        });
        // events.groupFlights(filtered)
        setSortedFlights(filtered);
    };


    useEffect(() => {
        filterFlights();
    }, [selectedOption]);


    return (
        <>
            <div className="radio-group-box">
                <div className="row row-gap-3">
                    <div className="col-lg-6">
                        <div className="radio-box">
                            <input
                                type="checkbox"
                                name="radio-option"
                                value="cheapest"
                                checked={selectedOption === 'cheapest'}
                                onChange={handleChange}
                            />
                            <label>
                                <h6 className="m-0">Cheapest</h6>
                                <span>Rs. {cheapestFlight} - Duration: {minToHours(cheapestFlightDuration)}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="radio-box">
                            <input
                                type="checkbox"
                                name="radio-option"
                                value="fastest"
                                checked={selectedOption === 'fastest'}
                                onChange={handleChange}
                            />
                            <label>
                                <h6 className="m-0">Fastest</h6>
                                <span>Rs. {fastestFlight} - Duration: {minToHours(fastestFlightDuration)}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>


            <div className="sort-box">
                <span>Sort by:</span>
                <ul className="m-0 p-0">
                    <li>
                        <button
                            type="button"
                            className="d-flex gap-1"
                            onClick={() => handleSortChange('duration')}
                        >
                            Duration
                            <img src="/images2/short.png" alt="short-icon"/>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="d-flex gap-1"
                            onClick={() => handleSortChange('arrival')}
                        >
                            Arrival
                            <img src="/images2/short.png" alt="short-icon"/>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="d-flex gap-1"
                            onClick={() => handleSortChange('stops')}
                        >
                            Stops
                            <img src="/images2/short.png" alt="short-icon"/>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="d-flex gap-1"
                            onClick={() => handleSortChange('price')}
                        >
                            Price
                            <img src="/images2/short.png" alt="short-icon"/>
                        </button>
                    </li>
                </ul>
                {/*<button className="btn btn-primary-theme">Select Manual</button>*/}
            </div>


        </>
    );
};

export default SortFlightFilters;
