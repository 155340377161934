import React, {useEffect, useState} from 'react'
import Select from "rc-select";
import debounce from 'lodash/debounce'
import _ from 'lodash'
import {flightsSearchFxn} from "../containers/flightSearch/actions";
import Airplane from "../assets/img/airplane.png"

const {Option} = Select

class CityTypeheadComponent extends React.Component {
    state = {
        arrList: [],
        value: [],
        fetching: false,
        city: '',
        cityId: ''
    }

    constructor(props) {
        super(props);
    }

    _getUserId = () => {
        let {cityId} = this.props
        if (cityId) {
            this.setState({
                cityId
            }, () => {
                this.fetchFlights()
            })
        } else {
            const listData = localStorage.getItem('cities') && JSON.parse(localStorage.getItem('cities')) || []
            this.setState({
                arrList: listData
            })
        }
    }

    componentDidMount() {
        this._getUserId()
    }

    fetchFlights = async (city) => {
        let {cityId} = this.state
        this.setState({arrList: [], fetching: true})
        let obj = {
            results: 30,
            count: 30,
            cityName: city,
            regExFilters: ['cityName']
        }
        if (cityId && !city) {
            obj.airportCode = cityId
        }
        if ((city && city.length > 2) || cityId) {
            let {data} = await flightsSearchFxn(obj);
            let dataNew = []
            if (data && data.length) {
                _.each(data, (item) => {
                    dataNew.push({
                        text: `${item.airportName} (${item.airportCode}) ${item.cityName}, ${item.countryName}`,
                        value: item.airportCode
                    })
                })
            }
            let newObj = {
                arrList: dataNew
            }
            if (cityId && !city && dataNew && dataNew.length) {
                let currentAgent = dataNew[0]
                newObj.city = {
                    key: currentAgent.value,
                    label: currentAgent.text,
                    text: currentAgent.text,
                }
            }
            this.setState(newObj)
        }
    }

    handleChange = value => {
        let {onSelect} = this.props;
        this.setState({
            city: value,
            cityId: value && value.key ? value.key : '',
            data: [],
            fetching: false
        })
        onSelect((value && value.key) || '');
        this.addItemInHistory(value)
    }
    addItemInHistory = (data) => {
        const listData = localStorage.getItem('cities') && JSON.parse(localStorage.getItem('cities')) || []
        let findD = _.find(listData, (item) => {
            return item && item?.key == data?.key
        })
        if (!findD) {
            listData.push({...data, text: data?.label})
            localStorage.setItem("cities", JSON.stringify(listData));
        }
    }

    render() {
        const {fetching, arrList, city, required = false} = this.state
        let {customStyle = '', placeholder = 'Select City'} = this.props
        return (
            <div className={'city-typehead'}>
                <Select
                    labelInValue
                    value={city || undefined}
                    className={`${customStyle}`}
                    style={{width: "100%"}}
                    placeholder={placeholder}
                    allowClear={true}
                    notFoundContent={fetching ? null : null}
                    filterOption={false}
                    showSearch={true}
                    onSearch={debounce(this.fetchFlights, 500)}
                    onChange={this.handleChange}>
                    {arrList.map((d) => {
                        return (
                            d.value ? <Option key={d.value} value={d.value} className={'flight-dropdown'}>
                                {d.text}
                            </Option> : null
                        )
                    })}
                </Select>
            </div>
        )
    }
}

export default CityTypeheadComponent
