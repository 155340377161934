import React, {useState} from "react"
import DownloadApplications from "../../../components/pageComponents/downloadApplications";
import {useDispatch} from "react-redux";
import {loginSendOtpFxn, loginSubmitLoginFxn, resendOtpFxn} from "../action";
import {Form, notification} from "../../../components/Elements";
import {useNavigate} from "react-router-dom";
import {customAxios, handleErr} from "../../../request";

let initState = {
    otpScreen: false,
    email: "",
    last2Digit: ""
}
const Login = (props) => {
    const navigate = useNavigate();
    let dispatch = useDispatch()
    let {form} = props;
    const {getFieldDecorator} = form;
    let [state, setState] = useState(initState);
    const [otpValues, setOtpValues] = useState(["", "", "", ""]);

    const events = {
        /* updateAxiosFxn:()=>{
             customAxios.interceptors.response.use(function (response) {
                 console.log(response);
                 let token = localStorage.getItem('token');
                 if (response.status == 200) {
                 }
                 if (token) {
                     response['Authorization'] = 'Bearer ' + token
                     response.headers['Authorization'] = 'Bearer ' + token
                 }
                 return response
             }, function (error) {
             })
         },*/
        handleSendOtp: (e) => {
            e.preventDefault();
            form.validateFields(async (err, values) => {
                if (!err) {
                    let x = await dispatch(loginSendOtpFxn(values));
                    if (x && x.success) {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                otpScreen: true,
                                email: values.email,
                            }
                        })
                    }
                } else {
                    notification.warning({message: "Please enter both email and password."})
                }
            })
        },
        handleOtpChange: (index, value) => {
            if (!isNaN(value) && value.length < 2) {
                const updatedOtp = [...otpValues];
                updatedOtp[index] = value;
                setOtpValues(updatedOtp);
                if (value && index < otpValues.length - 1) {
                    const nextInput = document.getElementById(`otp-input-${index + 1}`);
                    if (nextInput) {
                        nextInput.focus();
                    }
                } else if (!value && index > 0) {
                    const prevInput = document.getElementById(`otp-input-${index - 1}`);
                    if (prevInput) {
                        prevInput.focus();
                    }
                }
            }
        },
        handleSubmitLogin: (e) => {
            const {form} = props;
            e.preventDefault();
            form.validateFields(async (err, values) => {
                const otp = otpValues.join("");
                if (otp.trim() === "") {
                    notification.error({
                        message: "Please enter the OTP.",
                    });
                    return;
                }
                values.otp = otp;
                values.email = state.email;
                let x = await dispatch(loginSubmitLoginFxn(values));

                if (!x.error) {
                    localStorage.setItem("token", x.token);
                    localStorage.setItem("user", JSON.stringify(x.user));
                    // events.updateAxiosFxn();
                    dispatch({
                        type: "SET_CURRENT_USER",
                        user: x.user,
                    });

                    // navigate(`/flight-search`);

                    setTimeout(() => {
                        window.location.href = "/flight-search";
                    }, 100);
                } else {
                    // notification.error({
                    //     message: x.message,
                    // });
                }
            })
        },
        resendOtpFnc: (e) => {
            const {form} = props;
            e.preventDefault();
            form.validateFields(async (err, values) => {
                values.email = state.email;
                let x = await dispatch(resendOtpFxn(values));

                if (!x.error) {
                    notification.success({
                        message: x.message,
                    });
                } else {
                    notification.error({
                        message: x.message,
                    });
                }
            })
        },
        openForgotPassword: () => {
            navigate(`/forgot-password`);
        },
        openRegistration: () => {
            navigate(`/register`);
        }
    }


    const loginForm = (
        <section className="form-section">
            <img src="../images/login-banner.jpg" className="banner-login"/>
            <div className="container">
                <div className="row row-gap-3">
                    <div className="col-lg-7 me-auto">
                        <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5">
                            <Form onSubmit={events.handleSendOtp}>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputCompanyName" className="form-label">User Name</label>
                                    <Form.Item>
                                        {getFieldDecorator('email', {
                                            rules: [{
                                                required: true,
                                                message: 'Please input your email address!'
                                            }]
                                        })(
                                            <input placeholder="Enter your email" type={'email'}
                                                   className={'form-control'}
                                                   id={'new-email'} name={'new-email'}
                                                   autoComplete="new-email"/>
                                        )}
                                    </Form.Item>

                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputPassword1"
                                           className="form-label">Password</label>
                                    <Form.Item>
                                        {getFieldDecorator('password', {
                                            rules: [{
                                                required: true,
                                                message: 'Please input your Password!'
                                            }]
                                        })(
                                            <input placeholder="Password" type="password"
                                                   className={'form-control'}
                                                   id={'new-password'} name={'new-password'}
                                                   autoComplete="new-password"/>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                    <label className="form-check-label" htmlFor="exampleCheck1">Remember Me</label>
                                </div>

                                <button type="submit"
                                        className="btn btn-primary">
                                    Submit
                                </button>

                                <div className="mt-3 d-flex flex-wrap gap-3">
                                    <a onClick={events.openForgotPassword}
                                       className="text-decoration-none text-black fw-500">Forgot Password</a>
                                    <a onClick={events.openRegistration}
                                       className="ms-sm-auto text-decoration-none text-black fw-500">Create New
                                        Account</a>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    const otpForm = (
        <section className="form-section">
            <img src="../images/forgot-banner.jpg" className="banner-login"/>
            <div className="container">
                <div className="row row-gap-3">
                    <div className="col-lg-7 me-auto">
                        <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5">
                            <div className="head text-center mb-4">
                                <h3 className="mb-2">OTP Verification</h3>
                                <p className="m-0">
                                    We sent a verification code to your email address. Enter the code from the email
                                    address in the field
                                    below.
                                    <br/>********{state.last2Digit}</p>
                            </div>

                            <Form onSubmit={events.handleSubmitLogin}>
                                <div className="otp-field mb-4">
                                    {otpValues.map((digit, index) => (
                                        <input
                                            key={index}
                                            autoFocus={index == 0 ? true : false}
                                            type={'password'}
                                            value={digit}
                                            onChange={(e) => events.handleOtpChange(index, e.target.value)}
                                            id={`otp-input-${index}`}
                                            maxLength={1}
                                            className="otp-input"
                                        />
                                    ))}
                                </div>

                                <p className="resend mb-0 text-center">
                                    Didn't receive code?{" "}
                                    <a href="#" className="d-block fw-500 text-decoration-none"
                                       style={{color: "#2196f3"}}>
                                        Resend Code
                                    </a>
                                </p>

                                <button type="submit" className="btn btn-primary mt-4">
                                    Verify & Proceed
                                </button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

    return (
        <>
            <section className="login-module">
                <div className="container">
                    <div className="main-head py-3 py-lg-5">
                        <h3>Get Started Now!</h3>
                        <p className="m-0">Enter Credentials to get started now!</p>
                    </div>
                </div>
            </section>
            {state.otpScreen ? otpForm : loginForm}
            <DownloadApplications/>
        </>
    )
}
const WrappedLogin = Form.create()(Login)
export default WrappedLogin
