import React, {useEffect, useState} from "react"
import FlightImg from "../../../components/flightImg";
import {CabinObject, dateWithDay, displayDate, displayTime, minToHours} from "../../../components/utils/appUtils";
import {Popover, Tooltip} from "../../../components/Elements"
import LuggageIcon from '../../../assets/img/luggages.png'
import FlightFareIconsComponent from "../components/flightFareIconsComponent";
import WithStopComponent from "../flightListcomponents/withStopComponent";
import NonStopComponent from "../flightListcomponents/nonStopComponent";

const RoundFlightDetailsBox = (props) => {
    let {data, onFlightSelect, flightData, type, flightInfo} = props;
    let [selectedFlight, setSelectedFlight] = useState({})
    useEffect(() => {
        setSelectedFlight(flightData)
    }, [flightData])
    const handleFlightSelection = (flight) => {
        setSelectedFlight(flight);
        onFlightSelect(flight);
    };

    return (
        data && data[0] && data[0]['Segments'] && data[0]['Segments'].length ? <>
            <div className="book-flight mb-3">
                <div className="row row-gap-3 mb-3">
                    <div className="col-lg-12">
                        <div className={''}>
                            <div className="track-flights">
                                {data[0]['Segments'][0].length > 1 ?
                                    <WithStopComponent data={data[0]}/> :
                                    <NonStopComponent data={data[0]}/>}
                            </div>

                            <div className="class-price mt-2">
                                <div className="row">
                                    <div className="row row-gap-3">
                                        {data.map((item) => {
                                            let firstSegment = item['Segments'][0][0]
                                            return (
                                                <div className="col-lg-6">
                                                    <div className="main-box">
                                                        <input type="radio" name={`class-price-${type}`}
                                                               className="class-price"
                                                               checked={item['ResultIndex'] == flightData['ResultIndex']}
                                                               onChange={() => handleFlightSelection(item)}
                                                        />
                                                        <div className="box">
                                                            <span></span>
                                                            <small>{CabinObject[firstSegment['CabinClass']]}</small>
                                                            <h5>Rs. {item['Fare'] && item['Fare'].PublishedFare ? item['Fare'].PublishedFare : ""}</h5>
                                                        </div>
                                                    </div>
                                                    <FlightFareIconsComponent flight={item}
                                                                              bookingType={type == "outBound" ? "Outbound" : "Inbound"}
                                                                              traceId={flightInfo.traceId}/>

                                                </div>
                                            )
                                        })}


                                    </div>
                                    {/*     <div className="row row-gap-3 mt-2">
                                    <div className="col-lg-6">
                                        <button className="btn btn-grey text-nowrap">+1 more fare...</button>
                                    </div>
                                </div>*/}

                                    <div className="details d-flex gap-3 flex-wrap align-items-center mt-3">
                                        {/*<button
                                            className="btn btn-grey text-nowrap d-flex gap-2 align-items-center">View Details
                                        </button>*/}

                                        {selectedFlight['Segments'] && selectedFlight['Segments'][0] && selectedFlight['Segments'][0][0].NoOfSeatAvailable ?
                                            <p className="m-0" style={{fontSize: '14px'}}>
                                                {/*Flight Arrives after 1 Day(s)*/}
                                                <b className="ms-2"
                                                   style={{color: 'red'}}>
                                                    Seat(s)
                                                    left: {selectedFlight['Segments'][0][0].NoOfSeatAvailable}</b>
                                            </p> : null}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </> : null
    )
}
export default RoundFlightDetailsBox
