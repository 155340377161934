import React, {useEffect} from "react";
import FlightImg from "../../../components/flightImg";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {fixed2Digit} from "../../../components/utils/appUtils";

const RoundTripBook = (props) => {
    const {mappedOutboundFlights, mappedInboundFlights, flightInfo} = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const events = {
        confirmBooking: async (mappedInBoundFlights, mappedOutBoundFlights) => {
            const data = {
                mappedInBoundFlights,
                mappedOutBoundFlights
            }
            dispatch({
                type: "UPDATE_RESULT_INDEX",
                selectedFlight: {...data, FareBasisCode: {}},
                traceId: flightInfo.traceId,
                flightBookingType: "roundTripFlight"
            });
            console.log(flightInfo, "flight info---")
            navigate("/checkout");
        },
    };

    const renderFlightInfo = (flightData) => {
        if (!flightData || !flightData.Segments || !flightData.Segments.length) {
            return null;
        }

        const firstSegment = flightData.Segments[0][0];
        const lastSegment = flightData.Segments[0][flightData.Segments[0].length - 1];

        return (
            <li className="p-0">
                <span className="logo">
                    <img src={`../airlineLogos/${firstSegment?.Airline.AirlineCode}.gif`} alt=""/>
                </span>
                <h6>
                    {firstSegment?.Airline?.AirlineName || "Airline"}
                    <small className="d-block">
                        {firstSegment?.Airline?.AirlineCode}- {firstSegment?.Airline?.FlightNumber}, {lastSegment?.Airline?.FareClass}
                    </small>
                </h6>
                <div className="d-flex align-items-center" style={{gap: "10px"}}>
                    <h6>
                        {new Date(firstSegment?.Origin?.DepTime).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit"
                        })}
                        <small className="d-block">
                            {new Date(firstSegment?.Origin?.DepTime).toLocaleDateString([], {
                                day: "2-digit",
                                month: "short",
                                weekday: "short"
                            })}
                        </small>
                    </h6>
                    <span>
                        <FlightImg/>
                    </span>
                    <h6>
                        {new Date(lastSegment?.Destination?.ArrTime).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit"
                        })}
                        <small className="d-block">
                            {new Date(lastSegment?.Destination?.ArrTime).toLocaleDateString([], {
                                day: "2-digit",
                                month: "short",
                                weekday: "short"
                            })}
                        </small>
                    </h6>
                </div>
                <div className="d-flex flex-wrap text-center justify-content-center">
                    <div className="price text-nowrap">Rs. {flightData?.Fare?.PublishedFare || 0}</div>
                    {/*<small>Change Class</small>*/}
                </div>
            </li>
        );
    };

    const totalFare =
        (mappedInboundFlights?.Fare?.PublishedFare || 0) + (mappedOutboundFlights?.Fare?.PublishedFare || 0);

    return (
        <div className="grand-flight-total">
            <div className="track-flights">
                <ul className="overflow-auto">

                    {mappedOutboundFlights && (
                        <React.Fragment key="outbound">{renderFlightInfo(mappedOutboundFlights)}</React.Fragment>
                    )}
                    <li className="divide"></li>

                    {mappedInboundFlights && (
                        <React.Fragment key="inbound">{renderFlightInfo(mappedInboundFlights)}</React.Fragment>
                    )}


                    <li>
                        <div className="price red-box text-nowrap">
                            Rs. {fixed2Digit(totalFare)}
                        </div>
                        <div className="d-flex text-center justify-content-center gap-2">

                            <button
                                className="btn btn-primary text-nowrap"
                                type="button"
                                onClick={() => {
                                    events.confirmBooking(mappedInboundFlights, mappedOutboundFlights);
                                }}>
                                Book Now
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default RoundTripBook;
