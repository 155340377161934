import Home from "../web/home";
import Agents from "../web/agents";
import AboutUs from "../web/aboutUs";
import Events from "../web/events";
import ContactUs from "../web/contactUs";
import Dashboard from "../containers/dashboard";
import Login from "../containers/authications/login";
import Register from "../containers/authications/register";
import ForgotPassword from "../containers/authications/forgotPassword";
import FlightBooking from "../containers/flightSearch";
import FindFlight from "../containers/flightSearchResult";
import Checkout from "../containers/checkout";
import CheckoutNew from "../containers/checkout/indexNew";
import TicketResponse from "../containers/ticketResponse";
import ModifySearch from "../containers/flightSearchResult/components/modifySearch";
import TicketListComp from "../containers/ticketList";
import BookingDetails from "../containers/ticketList/bookingDetails";
import TicketDetails from "../containers/ticketList/eTicket";
import DepositList from "../containers/deposit/depositList";
import HoldTicketList from "../containers/ticketList/HoldTicketList";

let authMenu = [
    {
        name: "Login",
        title: "Login",
        component: Login,
        path: "/login"
    },
    {
        name: "Register",
        title: "Register",
        component: Register,
        path: "/register"
    },
    {
        name: "Forgot Password",
        title: "Forgot Password",
        component: ForgotPassword,
        path: "/forgot-password"
    },
]
let frontMenu = [
    {
        name: "Home",
        title: "Home",
        component: Home,
        path: "/"
    },
    {
        name: "Home",
        title: "Home",
        component: Home,
        path: "/home"
    },
    {
        name: "Agents",
        title: "Agents",
        component: Agents,
        path: "/agents"
    },
    {
        name: "About Us",
        title: "About Us",
        component: AboutUs,
        path: "/about-us"
    },
    {
        name: "Events",
        title: "Events",
        component: Events,
        path: "/events"
    },
    {
        name: "Contact Us",
        title: "Contact Us",
        component: ContactUs,
        path: "/contact-us"
    }
]
let menu = [
    {
        name: "Checkout",
        title: "Checkout",
        component: Checkout,
        path: "/checkout"
    },
    {
        name: "Find Flight",
        title: "Find Flight",
        component: FindFlight,
        path: "/flight-search-result"
    },
    {
        name: "Find Booking",
        title: "Find Booking",
        component: FlightBooking,
        path: "/flight-search"
    },
    {
        name: "Ticket Response",
        title: "Ticket Response",
        component: TicketResponse,
        path: "/response/:id"
    },
    {
        name: "Modify Flight Search",
        title: "Modify Flight Search",
        component: ModifySearch,
        path: "/modify-search"
    },
]
let innerMenu = [
    {
        name: "Dashboard",
        title: "Dashboard",
        component: Dashboard,
        path: "/dashboard"
    },
    {
        name: "Dashboard",
        title: "Dashboard",
        component: TicketListComp,
        path: "/ticket-list"
    },
    {
        name: "Dashboard",
        title: "Dashboard",
        component: HoldTicketList,
        path: "/hold-ticket-list"
    },
    {
        name: "Deposit List",
        title: "Deposit List",
        component: DepositList,
        path: "/deposit-List"
    },
]

let fullPageMenu = [
    {
        name: "Booking Details",
        title: "Booking Details",
        component: BookingDetails,
        path: "/booking-details"
    },
    {
        name: "Ticket Details",
        title: "Ticket Details",
        component: TicketDetails,
        path: "/ticket-details"
    }
]

export {frontMenu, menu, authMenu, innerMenu, fullPageMenu}
