import React from "react"
import {NavLink, useNavigate} from "react-router-dom";

const SideMenu = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="sidenav">
                <button className="slide-btn">
                    <img src="images2/double-back-arrow.png" alt="Back Arrow"/>
                </button>
                <ul>
                    <li>
                        <NavLink to="/flight-search">
                            <img src="../images2/search-white.png" alt="Search"/>
                            <span>Search Flight</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/ticket-list">
                            <img src="../images2/search-white.png" alt="Search"/>
                            <span>Manage Bookings</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/hold-ticket-list">
                            <img src="../images2/search-white.png" alt="Search"/>
                            <span>Booking Queue</span>
                        </NavLink>
                    </li>
                    <li>
                        <div className="accordion custom-accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="false"
                                        aria-controls="collapseOne">
                                        <img src="images2/search-white.png" alt="Search"/>
                                        <span>Payment Passbook</span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body p-0">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <span>Earning</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="active">
                                                    <span>Refunds</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <span>Declines</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <span>Payouts</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="accordion custom-accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                    >
                                        <img src="images2/search-white.png" alt="Search"/>
                                        <span>Deposits</span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseTwo"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body p-0">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <span>Add Deposit</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    className="active"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate("/deposit-List");
                                                    }}
                                                >
                                                    <span>Deposit List</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="#">
                            <img src="images2/search-white.png" alt="Search"/>
                            <span>Withdrawals</span>
                        </a>
                    </li>
                    <li>
                        <div className="accordion custom-accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree">
                                        <img src="images2/search-white.png" alt="Search"/>
                                        <span>Settings</span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body p-0">
                                        <ul>
                                            <li>
                                                <a href="#" className={'active'}>
                                                    <span>My Profile</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="">
                                                    <span>Update Markup</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="">
                                                    <span>Change Password</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="#">
                            <img src="images2/search-white.png" alt="Search"/>
                            <span>Latest Updates</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="images2/search-white.png" alt="Search"/>
                            <span>Callback Requests</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="images2/search-white.png" alt="Search"/>
                            <span>Supporting Team</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="images2/search-white.png" alt="Search"/>
                            <span>Raise A Complaint</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="images2/search-white.png" alt="Search"/>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}
export default SideMenu
