export const ticketListUrl = () => {
    return "/x-travel/ticket-list"
}

export const getBookingDetailsUrl = () => {
    return "/x-travel/booking-details"
}

export const bookSeatsUrl = () => {
    return "/x-travel/ssr-request"
}

export const singleTicketUrl = () => {
    return "/x-travel/single-ticket"
}

export const releasePnrUrl = () => {
    return "/x-travel/release-pnr"
}