import React, {useEffect, useState} from "react"
import {CabinObject} from "../../../components/utils/appUtils";
import {Modal} from "../../../components/Elements"
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import AirportImg from '../../../assets/img/airport.png'
import FlightFareIconsComponent from "../components/flightFareIconsComponent";
import WithStopComponent from "./withStopComponent";
import NonStopComponent from "./nonStopComponent";

const OneWayFlightDetailsBox = (props) => {
    let {data, flightInfo} = props;
    let [selectedFlight, setSelectedFlight] = useState({})
    let [newAgentMargin, setNewAgentMargin] = useState()
    const [showModal, setShowModal] = useState({
        visible: false,
        searchDestination: '',
        flightDestination: ''
    });
    const [showSelectedFlightModal, setShowSelectedFlightModal] = useState({
        visible: false,
        FareWithoutTax: 0,
        flightPrice: 0,
        agentMargin: 0,
        taxAndSurCharges: 0,
    });
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        if (data && data.length) {
            setSelectedFlight(data[0])
        }
    }, [data])

    const events = {
        confirmBooking: async (data) => {
            const searchOrigin = flightInfo.origin;
            const searchDestination = flightInfo.destination;
            const flightOrigin = data?.Segments?.[0]?.[0]?.Origin?.Airport?.AirportCode;
            const firstSegment = data?.Segments?.[0];
            const flightDestination = firstSegment?.[firstSegment.length - 1]?.Destination?.Airport?.AirportCode;
            if (searchDestination !== flightDestination) {
                setShowModal({
                    visible: true,
                    searchDestination: `${searchOrigin}-${searchDestination}`,
                    flightDestination: `${flightOrigin}-${flightDestination}`,
                });
            } else {
                dispatch({
                    type: "UPDATE_RESULT_INDEX",
                    selectedFlight: {...data, FareBasisCode: {}},
                    traceId: flightInfo.traceId,
                    flightBookingType: "onWayFlight"
                });

                navigate("/checkout");

            }
        },
        handleOpenAgentMarginModal: async (data) => {
            setShowSelectedFlightModal({
                visible: true,
                FareWithoutTax: data.FareWithoutTax || 0,
                agentMargin: data.agentXTravelMargin || 0,
                taxAndSurCharges: data.taxAndSurCharge || 0,
                flightPrice: data.PublishedFare || 0,
            })
        },
        handleUpdateAgentMargin: async (newMargin) => {
            setSelectedFlight((prevSelectedFlight) => {
                const updatedMargin = parseFloat(newMargin) || 0;
                const updatedPublishFare =
                    parseFloat(prevSelectedFlight.Fare.PublishedFare || 0) -
                    (parseFloat(prevSelectedFlight.Fare.agentXTravelMargin || 0)) +
                    updatedMargin;

                return {
                    ...prevSelectedFlight,
                    Fare: {
                        ...prevSelectedFlight.Fare,
                        agentXTravelMargin: updatedMargin,
                        PublishedFare: updatedPublishFare,
                    }
                };
            });
            setNewAgentMargin('');
            setShowSelectedFlightModal((prevState) => ({
                ...prevState,
                visible: false,
            }));
        }


    }
    const handleModalAction = (action) => {
        if (action === "yes") {
            setShowModal({...showModal, visible: false});
            dispatch({
                type: "UPDATE_RESULT_INDEX",
                selectedFlight: {...selectedFlight, FareBasisCode: {}},
                traceId: flightInfo.traceId,
            });
            navigate("/checkout");
        } else {
            setShowModal({...showModal, visible: false});
        }
    };
    return (
        selectedFlight && selectedFlight['Segments'] && selectedFlight['Segments'].length ? <>
            <div className="book-flight mb-3">
                <div className="row row-gap-3 mb-3">
                    <div className="col-lg-10">
                        {/*accordion-flight-track*/}
                        <div className={''}>
                            <div className="track-flights">
                                {selectedFlight['Segments'][0].length > 1 ?
                                    <WithStopComponent data={selectedFlight}/> :
                                    <NonStopComponent data={selectedFlight}/>}
                            </div>

                            <div className="class-price mt-2">
                                <div className="row">
                                    <div className="row row-gap-3">
                                        {data.map((item, index) => {
                                            let firstSegment = item['Segments'][0][0]
                                            return (
                                                <div className="col-lg-4">
                                                    <div className="main-box" onClick={() => {
                                                        setSelectedFlight(item)
                                                    }}>
                                                        <input type="radio" className="class-price"
                                                               value={item['ResultIndex']}
                                                               checked={selectedFlight['ResultIndex'] == item['ResultIndex']}
                                                        />
                                                        <div className="box">
                                                            <span></span>
                                                            <small>{CabinObject[firstSegment['CabinClass']]}</small>
                                                            <h5>Rs. {item['Fare'] && item['Fare'].PublishedFare ? item['Fare'].PublishedFare : ""}</h5>
                                                        </div>
                                                    </div>
                                                    <FlightFareIconsComponent flight={item}
                                                                              traceId={flightInfo.traceId}/>
                                                </div>
                                            )
                                        })}


                                    </div>


                                    <div className="details d-flex gap-3 flex-wrap align-items-center mt-3">
                                        {/*<button
                                            className="btn btn-grey text-nowrap d-flex gap-2 align-items-center">View Details
                                        </button>*/}

                                        {/*    {selectedFlight['Segments'][0][0].NoOfSeatAvailable ?
                                            <p className="m-0" style={{fontSize: '14px'}}>
                                                <b className="ms-2"
                                                   style={{color: 'red'}}>
                                                    Seat(s)
                                                    left: {selectedFlight['Segments'][0][0].NoOfSeatAvailable}</b>
                                            </p> : null}*/}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-2" key={selectedFlight}>
                        <div className="d-flex flex-wrap gap-3 h-100">
                            <div className="total-price cursor-pointer" onClick={() => {
                                events.handleOpenAgentMarginModal(selectedFlight.Fare)
                            }}>
                                <span>Total Price</span>
                                <h5>Rs. {selectedFlight.Fare && selectedFlight.Fare.PublishedFare ? selectedFlight.Fare.PublishedFare : ""}
                                </h5>
                                <small></small>
                                <button
                                    onClick={() => {
                                        events.confirmBooking(selectedFlight)
                                    }}
                                    className="btn btn-primary">Book Now
                                </button>
                            </div>
                            {/*     <button className="btn btn-primary-theme mt-auto w-100"
                                    style={{fontSize: '14px'}}>Change Class
                            </button>*/}
                        </div>
                    </div>
                </div>

            </div>
            {showModal.visible && (
                <Modal
                    visible={showModal.visible}
                    className={'popup-confirm'}
                    width={'38%'}
                    onClose={() => setShowModal({...showModal, visible: false})}
                >
                    <div className={'mt-1 alert-popup'}>
                        <div className={'mb-4'}>
                            <img src={AirportImg} alt={'airportimg'} width={'30%'}/>
                        </div>
                        <h3 className={'mb-4'}>Different Airport Alert</h3>
                        <p className={'mb-4'}>
                            You have searched for Outbound <strong>({showModal.searchDestination})</strong>,
                            however, this flight operates from Outbound <strong>({showModal.flightDestination})</strong>.

                        </p>
                        <h3 className={'mb-4'}>Do you wish to continue?</h3>
                        <div className="d-flex justify-content-center gap-3 mb-2">
                            <button
                                className="customYesBtn"
                                onClick={() => handleModalAction("yes")}
                            >
                                Yes
                            </button>
                            <button
                                className="customNoBtn"
                                onClick={() => handleModalAction("no")}
                            >
                                No
                            </button>

                        </div>
                    </div>
                </Modal>
            )}
        </> : null
    )
}

export default OneWayFlightDetailsBox
