import React, {useEffect, useState} from "react";
import {Modal, Tooltip} from '../../components/Elements';
import _ from "lodash"

const SeatsLayoutModal = (props) => {
    const {
        visible,
        onClose,
        seatsObj,
        selectedFlight,
        onSeatConfirm,
        selectedSeats,
        setSelectedSeats,
        adultPassengers,
        childPassengers
    } = props;
    const [allPass, setAllPass] = useState([])
    const [seats, setSeats] = useState([])
    const [selectedPass, setSelectedPass] = useState({
        index: 0,
        type: "adult"
    })
    let [segmentList, setSegmentList] = useState([])
    const [activePassenger, setActivePassenger] = useState(null);
    let totalPass = (adultPassengers.length || 0) + (childPassengers.length || 0);


    // console.log(segmentList, "segment list---")

    useEffect(() => {
        eventsFxn.defaultSeatSetup()
    }, [])

    const eventsFxn = {
        allPassSet: () => {
            let allAdultPass = []
            let allChildPass = []
            _.each(adultPassengers, (item, index) => {
                if (item) {
                    allAdultPass.push({
                        ...item, passType: "adult", index
                    })
                }
            })
            _.each(childPassengers, (item, index) => {
                if (item) {
                    allChildPass.push({
                        ...item, passType: "child", index
                    })
                }
            })
            setAllPass([...allAdultPass, ...allChildPass])
        },
        defaultSeatSetup: () => {
            const segments = selectedFlight?.Segments?.[0] || [];
            let seats = []
            let arrList = []
            _.each(segments, (item) => {
                let {Origin: {Airport: originAirport}, Destination: {Airport: destinationAirport}} = item;
                let obj = {
                    origin: originAirport['CityCode'],
                    destination: destinationAirport['CityCode'],
                    seats,
                    totalPrice: 0
                }
                obj.key = `${obj.origin} - ${obj.destination}`
                arrList.push(obj)
            })
            setSegmentList(arrList);
        },
        chooseSeat: (segmentIndex, seat, segmentKey) => {
            let cloneSeat = _.cloneDeep(segmentList); // Use cloneDeep to avoid reference issues
            let findD = _.find(cloneSeat, (item) => {
                return item.key === segmentKey; // Use `===` for comparison
            });
            if (findD) {
                let findExistIndex = _.findIndex(findD.seats, (item) => item && item.Code === seat.Code);
                if (findExistIndex < 0) {
                    let findEmptyIndex = _.findIndex(findD.seats, (item) => item && !item.Code);
                    if (findEmptyIndex >= 0) {
                        findD.seats[findEmptyIndex] = seat;
                    } else {
                        findD.seats = [...findD.seats, seat]; // Append and slice
                    }
                } else {
                    findD.seats[findExistIndex] = {}
                }
                findD.seats = findD.seats.slice(-totalPass);
            }
            setSegmentList(cloneSeat); // Update state
        }
    }
    useEffect(() => {
        if (selectedSeats && Object.keys(selectedSeats).length) {
            setSelectedSeats(selectedSeats);
        } else {
            const mapping = {};
            adultPassengers.forEach((_, index) => {
                mapping[`adult-${index}`] = [];
            });
            childPassengers.forEach((_, index) => {
                mapping[`child-${index}`] = [];
            });
            setSelectedSeats(mapping);
        }
    }, [selectedSeats, adultPassengers, childPassengers]);

    const handlePassengerSelection = (index, type) => {
        setActivePassenger({index, type});
    };

    const handleConfirmSeatSelection = () => {
        setSegmentList((prevSegmentList) => {
            console.log(prevSegmentList, "seat===");
            onSeatConfirm(prevSegmentList);
            return prevSegmentList;
        });
        onClose();
    };




    const segments = selectedFlight?.Segments?.[0] || [];
    const [activeSegmentIndex, setActiveSegmentIndex] = useState(0);


    const activePassengerKey = `${activePassenger?.type}-${activePassenger?.index}`;
    const activePassengerSeats = selectedSeats[activePassengerKey] || [];


    const handleSelectAllPassengers = () => {
        setActivePassenger(null);
    };


    const renderPassengerCard = (passenger, type, index) => {
        const key = `${type}-${index}`;
        const isActive = activePassengerKey === key;
        return (
            <div
                key={key}
                className={`box seat-details-box passenger-card mb-3 mt-3 ${isActive ? "active" : ""}`}
                onClick={() => handlePassengerSelection(index, type)}
                style={{
                    cursor: "pointer",
                    border: isActive ? "1px solid blue" : "1px solid #ccc",
                    padding: "0.5rem"
                }}>
                <h6 className="text-uppercase mb-0">{type} {index + 1}</h6>
                <div className={'d-flex'} style={{gap: 7}}>
                    {segmentList && segmentList.length ? segmentList.map((segment, segmentIndex) => {
                        let currentSeat = segment.seats[index];
                        return (
                            <div key={segmentIndex}
                                 style={{padding: "0.5rem", border: "1px solid #ccc", marginTop: "5px", flex: 1}}>
                                <strong className={'f-12 text-gray'}>
                                    {segment.key}
                                </strong>
                                <div className={'f-12'}>
                                    <strong>Seat No:</strong>{" "}
                                    <span
                                        className="selected-seat">{currentSeat && currentSeat['Code'] ? currentSeat['Code'] : "--"}</span>
                                </div>
                                <div className="d-flex justify-content-between f-12 text-gray">
                                    <strong>Total Price:</strong>{" "}
                                    <span>{currentSeat ? `${currentSeat['Price'] ? currentSeat['Price'] : ""} ${currentSeat.Currency}` : "N/A"}</span>
                                </div>
                            </div>
                        )
                    }) : null}


                </div>
            </div>
        );
    };


    const onSelectSeatFromDetails = (segmentIndex) => {
        setActiveSegmentIndex(segmentIndex);
    };

    useEffect(() => {
        setSeats(selectedSeats)
    }, [selectedSeats])

    return (
        <Modal visible={visible} onClose={onClose} width={"60%"}>
            {seatsObj && (
                <>
                    <h5>Select Seats</h5>
                    <div className="seat-selection">
                        <div className="flightbox">
                            <div className="flightbody">
                                <div className="row">

                                    <div className="col-md-4">
                                        {segmentList && segmentList.length ? segmentList.map((segment, segmentIndex) => {
                                            return (
                                                <div className="box seat-details-box mt-2" key={segment.key}
                                                     onClick={() => onSelectSeatFromDetails(segmentIndex)}
                                                     style={{
                                                         cursor: "pointer",
                                                         border: activeSegmentIndex === segmentIndex ? "2px solid blue" : "1px solid #ccc"
                                                     }}>
                                                    <h6 className={'d-flex align-items-center'}>Seat Details - &nbsp;
                                                        <small className="d-block">({segment.key})</small></h6>
                                                    <div className="seat-details">
                                                        {/*  <div>
                                                            <strong>Segment {segmentIndex + 1}:</strong>{" "}
                                                            {segment.origin} - {segment.destination}
                                                        </div>*/}
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <strong>Seat No:</strong>{" "}
                                                                <span className="selected-seat">
                                                                    {_.times(totalPass, (index) => {
                                                                        let currentSeat = segment.seats[index];
                                                                        return (
                                                                            <>
                                                                                {currentSeat && currentSeat['Code'] ? currentSeat['Code'] : "-"} {index < (totalPass - 1) ? ", " : ""}
                                                                            </>
                                                                        )
                                                                    })}
                                                                </span>
                                                            </div>
                                                            <a className="btn-link cursor-pointer"
                                                               onClick={() => onSelectSeatFromDetails(segmentIndex)}>
                                                                Select Seat
                                                            </a>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <strong>Total Price:</strong>{" "}
                                                            <span>
                                                                    {Object.values(selectedSeats).flat().reduce((total, seat) => total + (seat?.Price || 0), 0)}{" "}
                                                                {Object.values(selectedSeats).flat()[0]?.Currency || ""}
                                                                </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : null}

                                        <div className={'mt-1'}>
                                            <div>
                                                {adultPassengers?.map((passenger, index) => {
                                                    return renderPassengerCard(passenger, 'adult', index)
                                                })}
                                                {childPassengers?.map((passenger, indexB) => {
                                                    let index = indexB + adultPassengers.length;
                                                    return renderPassengerCard(passenger, 'child', index)
                                                })}
                                            </div>

                                        </div>
                                        <div className="confirm-seat mt-3 align-center">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleConfirmSeatSelection}>
                                                Confirm
                                            </button>
                                        </div>

                                        <div className="hintbox">

                                            <div className="hintrow">
                                                <span className="hintseat">
                                                        <dfn className="seathint quitezone">
                                                            <span></span>
                                                        </dfn>
                                                </span>
                                                <span className="hinttext">Quiet zone</span>
                                            </div>
                                            <div className="hintrow">
                                        <span className="hintseat">
                                            <dfn className="seathint hintgood">
                                                <span></span>
                                            </dfn>
                                        </span>
                                                <span className="hinttext">Selected Seat</span>
                                            </div>
                                            <div className="hintrow">
                                        <span className="hintseat">
                                            <dfn className="seathint hintbooked">
                                                <span></span>
                                            </dfn>
                                        </span>
                                                <span className="hinttext">Occupied Seat</span>
                                            </div>
                                            <div className="hintrow">
                                        <span className="hintseat">
                                            <dfn className="seathint">
                                                <i></i>
                                            </dfn>
                                        </span>
                                                <span className="hinttext">Bulkhead Seat</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 seat-layout">
                                        <div className="content d-flex justify-content-center">
                                            <div className="seat-list">
                                                {segmentList && segmentList.length ? segmentList.map((segment, segmentIndex) => {
                                                    let hasDeck1LabelShown = false;
                                                    let selectedSeatsArr = _.map(segment.seats, 'Code');
                                                    return (
                                                        <div
                                                            key={segmentIndex}
                                                            className={`segment-rows ${activeSegmentIndex === segmentIndex ? "active" : "hidden"}`}
                                                        >
                                                            <h6 className="header-title">
                                                                {segment.key}
                                                            </h6>
                                                            {seatsObj?.[segmentIndex]?.RowSeats?.map((row, rowIndex) => {
                                                                let showDeck1Label = false;
                                                                if (row.Seats.some((seat) => seat.Deck === 1) && !hasDeck1LabelShown) {
                                                                    showDeck1Label = true;
                                                                    hasDeck1LabelShown = true;
                                                                }

                                                                const seatLabels = [...new Set(row.Seats.map((seat) => seat.SeatNo))].sort();
                                                                const isWideBody = seatLabels.includes("G");

                                                                const leftSide = ["A", "B", "C"];
                                                                const middle = isWideBody ? ["D", "E", "F", "G"] : ["D", "E", "F"];
                                                                const rightSide = isWideBody ? ["H", "I", "J", "K"] : [];

                                                                return (
                                                                    <div key={rowIndex} className="row-wrapper">
                                                                        {showDeck1Label && (
                                                                            <div className="deck-label">
                                                                                <strong>Deck 1</strong>
                                                                            </div>
                                                                        )}

                                                                        <div className="row">
                                                                            <div
                                                                                className={`seatrobox col-md-${isWideBody ? "4" : "6"}`}>
                                                                                <ul className="seatrow">
                                                                                    {row.Seats.filter((seat) => leftSide.includes(seat.SeatNo)).map((seat, seatIndex) => (
                                                                                        <li key={seatIndex}
                                                                                            className={`seat ${seat.AvailablityType === 3 ? "booked" : ""}`}
                                                                                            onClick={() => eventsFxn.chooseSeat(segmentIndex, seat, segment.key)}
                                                                                            style={{pointerEvents: seat.AvailablityType === 3 ? "none" : "auto"}}>
                                                                                            <Tooltip
                                                                                                title={`Seat: ${seat.Code}, Price: ${seat.Price} ${seat.Currency}`}>
                                                                                                 <span
                                                                                                     className={`seat-code ${selectedSeatsArr.includes(seat['Code']) ? "selected" : ""}`}>
                                                                                                    {seat.Code}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>

                                                                            <div
                                                                                className={`seatrobox col-md-${isWideBody ? "4" : "6"}`}>
                                                                                <ul className="seatrow">
                                                                                    {row.Seats.filter((seat) => middle.includes(seat.SeatNo)).map((seat, seatIndex) => (
                                                                                        <li
                                                                                            key={seatIndex}
                                                                                            className={`seat ${seat.AvailablityType === 3 ? "booked" : ""}`}
                                                                                            onClick={() => eventsFxn.chooseSeat(segmentIndex, seat, segment.key)}
                                                                                            style={{pointerEvents: seat.AvailablityType === 3 ? "none" : "auto"}}
                                                                                        >
                                                                                            <Tooltip
                                                                                                title={`Seat: ${seat.Code}, Price: ${seat.Price} ${seat.Currency}`}>
                                                                                                          <span
                                                                                                              className={`seat-code ${selectedSeats[`segment-${segmentIndex}`]?.[activePassengerKey]?.some(selectedSeat => selectedSeat?.Code === seat.Code) ? "selected" : ""}`}>

                                                        {seat.Code}
                                                    </span>
                                                                                            </Tooltip>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>

                                                                            {isWideBody && (
                                                                                <div className="seatrobox col-md-4">
                                                                                    <ul className="seatrow">
                                                                                        {row.Seats.filter((seat) => rightSide.includes(seat.SeatNo)).map((seat, seatIndex) => (
                                                                                            <li
                                                                                                key={seatIndex}
                                                                                                className={`seat ${seat.AvailablityType === 3 ? "booked" : ""}`}
                                                                                                onClick={() => eventsFxn.chooseSeat(segmentIndex, seat)}
                                                                                                style={{pointerEvents: seat.AvailablityType === 3 ? "none" : "auto"}}
                                                                                            >
                                                                                                <Tooltip
                                                                                                    title={`Seat: ${seat.Code}, Price: ${seat.Price} ${seat.Currency}`}>
                                                                                                          <span
                                                                                                              className={`seat-code ${Object.values(selectedSeats[`segment-${segmentIndex}`] || {}).flat().some(selectedSeat => selectedSeat?.Code === seat.Code) ? "selected" : ""}`}>

                                                            {seat.Code}
                                                        </span>
                                                                                                </Tooltip>
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                }) : null}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}


        </Modal>
    );
};

export default SeatsLayoutModal;
