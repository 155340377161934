import React, {useEffect, useState} from "react";
import FlightImg from "../../components/flightImg";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Icon} from "../../components/Elements";
import OverseasFlightInfoComponent from "./components/OverseasFlightInfoComponent";
import ReviewBookingComponent from "./components/ReviewBookingComponent";
import {FlightSummary, ReturnFlightSummary} from "./components/summary";
import _ from "lodash";
import {holdFlightBookFxn, lccFlightTicketFxn} from "../flightSearch/actions";
import Passengers from "./components/passengers";
import PaymentComponent from "./components/paymentComponent";
import PromotionalModal from "../ModalComponent/promotionalModal";
import ArrowIcon from "../../assets/img/arrow.png";

const OverseasBooking = (props) => {
    const {flightInfo, selectedFlight, flightObj, traceId, fareObject, bookingType, flightBookingType} = props;
    let {mappedOutBoundFlights, mappedInBoundFlights} = selectedFlight;
    let [state, setState] = useState({});
    let [ticketId, setTicketId] = useState("");
    const [totalPaidAmount, setTotalPaidAmount] = useState(0)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(1)
    const [selectedPayment, setSelectedPayment] = useState('');
    let [passengerState, setPassengerState] = useState({})
    let [bookingFlightData, setBookingFlightData] = useState({})
    const [showPromoModal, setShowPromoModal] = useState(false);
    const [promotionData, setPromotionData] = useState({})
    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
    };
    useEffect(() => {
        events.setFlightBookingFxn()
    }, [bookingType])
    const events = {
        setFlightBookingFxn: () => {
            console.log(selectedFlight);
            // let bookingFlightData = bookingType == "Inbound" ? selectedFlight['mappedInBoundFlights'] : selectedFlight['mappedOutBoundFlights'];
            // setBookingFlightData(bookingFlightData)
        },
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },

        addPassenger: () => {
            setActiveTab(2)
            window.scrollTo(0, 0)
        },
        handleBookTicket: () => {
            setShowPromoModal(true);
        },
        bookTicket: async (promoData = null) => {
            setShowPromoModal(false)
            let {adultPassengers = [], childPassengers = [], infantPassengers = [], contactState = {}, gstState = {}} = passengerState;
            let allPassenger = [];
            if (adultPassengers && adultPassengers.length) {
                _.each(adultPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 1})
                })
            }
            if (childPassengers && childPassengers.length) {
                _.each(childPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 2})
                })
            }
            if (infantPassengers && infantPassengers.length) {
                _.each(infantPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 3})
                })
            }
            let obj = {
                traceId: traceId,
                resultIndex: selectedFlight['ResultIndex'],
                allPassenger,
                fareQuote: fareObject,
                isLcc: selectedFlight['IsLCC'],
                ...passengerState.state,
                ...contactState,
                ...gstState,
                ...promotionData,
                totalPaidAmount
            }
            let {ticketId, sessionId, success} = await dispatch(lccFlightTicketFxn(obj));
            console.log(success, '=====dccd');
            if (success) {
                setTicketId(ticketId);
                dispatch({type: "UPDATE_SESSION_ID", sessionId: sessionId})
                // navigate(`/response/${ticketId}`, {state: {params: {sessionId}}});
                navigate(`/response/${ticketId}?sessionId=${sessionId}`, {state: {params: {sessionId}}});
            } else {
                navigate(`/response/${ticketId}`, {state: {params: {sessionId}}});
            }
        },
        goBackToPreviousTab: () => {
            if (activeTab > 0) {
                let currentTab = activeTab - 1;
                window.scrollTo(0, 0)
                if (currentTab == 0) {
                    navigate('/flight-search')
                } else {
                    setActiveTab(currentTab);
                }
            }
        },
        holdBookingFxn: async () => {
            let {adultPassengers = [], childPassengers = [], infantPassengers = [], contactState = {}, gstState = {}} = passengerState;
            let allPassenger = [];
            if (adultPassengers && adultPassengers.length) {
                _.each(adultPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 1})
                })
            }
            if (childPassengers && childPassengers.length) {
                _.each(childPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 2})
                })
            }
            if (infantPassengers && infantPassengers.length) {
                _.each(infantPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 3})
                })
            }
            let obj = {
                traceId: traceId,
                resultIndex: selectedFlight['ResultIndex'],
                allPassenger,
                fareQuote: fareObject,
                isLcc: selectedFlight['IsLCC'],
                ...passengerState.state,
                ...contactState,
                ...gstState,
                ...promotionData
            }
            let {data, ticketId, sessionId, success} = await dispatch(holdFlightBookFxn(obj));

            if (success) {
                setTicketId(ticketId);
                dispatch({type: "UPDATE_SESSION_ID", sessionId: sessionId})
                // navigate(`/response/${ticketId}`);
                navigate(`/response/${ticketId}`, {state: {params: {sessionId}, data, passengerState}});
            } else {
                // navigate(`/response/${ticketId}`);
            }
        },
    };

    return (
        <div className="checkout-box bg-gray">

            <div className="flight-booking bg-white spacing-40">
                <div className="container position-relative">
                    <div className="col-lg-12">
                        <ul className="nav nav-tabs border-0 gap-3" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link  rounded-pill border-0 ${activeTab == 1 ? "active" : ""}`}
                                    id="flight-itinerary-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#flight-itinerary"
                                    type="button"
                                    role="tab"
                                    aria-controls="flight-itinerary"
                                    disabled={activeTab !== 1}
                                    aria-selected="true">
                                    <Icon type={'plane'}/>
                                    Flight Itinerary
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link  rounded-pill border-0 ${activeTab == 2 ? "active" : ""}`}
                                    id="passenger-details-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#passenger-details"
                                    type="button"
                                    role="tab"
                                    disabled={activeTab !== 2}
                                    aria-controls="passenger-details"
                                    aria-selected="false">
                                    Passenger Details
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link  rounded-pill border-0 ${activeTab == 3 ? "active" : ""}`}
                                    id="review-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#review"
                                    type="button"
                                    role="tab"
                                    disabled={activeTab !== 3}
                                    aria-controls="review"
                                    aria-selected="false"
                                >
                                    Review
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link  rounded-pill border-0 ${activeTab == 4 ? "active" : ""}`}
                                    id="payment-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#payment"
                                    type="button"
                                    role="tab"
                                    disabled={activeTab !== 4}
                                    aria-controls="payment"
                                    aria-selected="false">
                                    Payment
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className="spacing-40 container">

                <div className="row row-gap-3">
                    <div className="col-lg-9">
                        <div className="head d-flex gap-3 align-items-center mb-3">
                            <span>Visa, Passport & Health Regulations</span>
                            <button
                                className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2"
                                onClick={() => window.history.back()}>{/* Use a function to go back */}
                                Back to Search
                                <img src={ArrowIcon} alt={'arrow'}/>
                            </button>
                        </div>
                        <div className="find-flight-main">
                            <OverseasFlightInfoComponent {...props} bookingType={bookingType}/>

                            {/*<div className="accordion-flight-track">
                                <p className="note">
                                    Require to change Plane - Layover Time: 3h 15m
                                </p>
                            </div>*/}

                        </div>

                        <div className="tab-content" id="myTabContent">
                            <div className={`tab-pane fade ${activeTab == 1 ? "show active" : ""}`}
                                 id="flight-itinerary" role="tabpanel"
                                 aria-labelledby="flight-itinerary-tab">

                                <div className="find-flight-main">
                                    <button className="btn btn-primary w-100 mt-3"
                                            onClick={() => events.addPassenger()}>ADD
                                        PASSENGERS DETAILS
                                    </button>
                                </div>
                            </div>


                            <div className={`tab-pane fade ${activeTab == 2 ? "show active" : ""}`}
                                 id="passenger-details" role="tabpanel"
                                 aria-labelledby="passenger-details-tab">
                                <Passengers
                                    {...props}
                                    selectedFlight={selectedFlight}
                                    flightObj={flightObj} callbackState={(resp) => {
                                    setPassengerState(resp)
                                    setActiveTab(3)
                                    window.scrollTo(0, 0)
                                }}/>
                            </div>


                            <div className={`tab-pane fade ${activeTab === 3 ? "show active" : ""}`}
                                 id="review"
                                 role="tabpanel"
                                 aria-labelledby="review-tab">
                                <ReviewBookingComponent
                                    passengerState={passengerState}
                                    fareObject={fareObject}
                                    traceId={traceId}
                                    selectedFlight={selectedFlight}
                                    flightObj={flightObj}
                                    bookState={events.holdBookingFxn}
                                    callbackState={() => {
                                        setActiveTab(4)
                                        window.scrollTo(0, 0)
                                    }}/>

                            </div>


                            <div className={`tab-pane fade ${activeTab == 4 ? "show active" : ""}`} id="payment"
                                 role="tabpanel"
                                 aria-labelledby="payment-tab">
                                <PaymentComponent passengerState={passengerState}
                                                  fareObject={fareObject}
                                                  traceId={traceId}
                                                  state={state}
                                                  selectedFlight={selectedFlight}
                                                  callbackState={events.bookTicket}
                                                  totalPaidAmount={totalPaidAmount}
                                />
                            </div>

                        </div>


                    </div>
                    <div className="col-lg-3">
                        {
                            selectedFlight && selectedFlight['ResultIndex'] ? (
                                <>
                                    <FlightSummary
                                        flightData={selectedFlight}
                                        passengerState={passengerState}
                                        callBack={(totalPaidAmount) => {
                                            setTotalPaidAmount(totalPaidAmount)
                                        }}
                                    />
                                </>
                            ) : null
                        }
                    </div>
                </div>
            </div>

            {showPromoModal && (
                <PromotionalModal
                    visible={showPromoModal}
                    onClose={() => setShowPromoModal(false)}
                    onYesClick={(promoData) => events.bookTicket(promoData)}
                    onNoClick={() => events.bookTicket()}
                    callbackState={(resp) => setPromotionData(resp)}
                />
            )}
        </div>

    );
};

export default OverseasBooking;
