import React, { useState } from "react";
import { Modal } from "../../components/Elements";
import TicketsImg from "../../assets/img/tickets.png";
import { CustomFormFields } from "../../components/utils/customFormUtils";

const PromotionalModal = (props) => {
    const { visible, onClose, onYesClick, onNoClick, callbackState } = props;

    const [formData, setFormData] = useState({
        corporateCode: "",
        tourCode: "",
        remarks: "",
        endorsementCode: "",
    });

    const handleChange = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
        callbackState(formData)
    };

    const fields = [
        {
            key: "corporateCode",
            label: "Corporate Code",
            placeholder: "Enter your Corporate Code Here",
            span: "col-md-6",
        },
        {
            key: "tourCode",
            placeholder: "Enter your Tour Code Here",
            label: "Tour Code",
            span: "col-md-6",
        },
        {
            key: "remarks",
            placeholder: "Enter your Remarks Here",
            label: "Remarks",
            span: "col-md-6",
        },
        {
            key: "endorsementCode",
            placeholder: "Enter your Endorsement Code Here",
            label: "Endorsement Code",
            span: "col-md-6",
        },
    ];

    return (
        <Modal visible={visible} width={'42%'} onClose={onClose} className={'popup-confirm'}>
            <div className={'alert-popup'}>
                <div className={'mb-3'}>
                    <img src={TicketsImg} alt={'airportimg'} width={'28%'} />
                </div>
                <h3 className={'mb-3'}>This will make booking and Generate E-Ticket. Do you want to proceed?</h3>
                <p className={'mb-3'}>Enter the Airlines Promotional code, if any?</p>
                <div className={'mt-3'}>
                    <div className="row row-gap-1">
                        {fields.map((item) => (
                            <div className={item.span} key={item.key}>
                                <CustomFormFields
                                    item={{
                                        size: 'small',
                                        ...item,
                                        required: item.required,
                                        value: formData[item.key],
                                        onChange: (value) => handleChange(item.key, value),
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="d-flex justify-content-center gap-3 mb-1 mt-2">
                    <button className="customYesBtn" onClick={onYesClick}>Yes</button>
                    <button className="customNoBtn" onClick={onClose}>No</button>
                </div>
            </div>
        </Modal>
    );
};

export default PromotionalModal;
