import React, {useState, useEffect} from "react";
import "./bookingDetails.css";
import {getBookingDetailsFxn} from "./action";
import moment from "moment";
import {getUser} from "../authications/action";
import {useDispatch} from "react-redux";
import {passengerTypeObject} from "../../components/utils/appUtils";
import {singleTicketFxn} from "../flightSearch/actions";

const BookingDetails = () => {
    let dispatch = useDispatch()
    const [data, setData] = useState(null);
    const [pnr, setPnr] = useState();
    const [user, setUser] = useState(null);

    useEffect(async () => {
        const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))
        const userData = await dispatch(getUser(user._id))
        if (userData) {
            setUser(userData);
        }
    }, []);

    const fetchBookingDetails = async (params) => {
        try {
            if (params) {
                const response = await dispatch(getBookingDetailsFxn(params))
                setData(response?.data?.FlightItinerary);
            }
        } catch (error) {
            console.error("Error fetching booking details:", error);
        }
    };

    const loadData = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const ticketId = queryParams.get('ticketId');
        let {data} = await dispatch(singleTicketFxn({ticketId}))
        console.log(data, 'data')
        if (data && data.response) {
            setData(data.response.FlightItinerary);
            setPnr(data.response.PNR);
        } else if (data && data.bookingResponse) {
            setData(data.bookingResponse.FlightItinerary);
            setPnr(data.bookingResponse.PNR);
        }
    }

    useEffect(() => {
        // const params = {};
        // const urlParams = new URLSearchParams(window.location.search);
        // params.bookingId = urlParams.get("bookingId");
        // params.PNR = urlParams.get("pnr");
        // params.FirstName = urlParams.get("firstName");
        // params.LastName = urlParams.get("lastName");
        // params.traceId = urlParams.get("traceId");
        // if (params.PNR) {
        //     setPnr(params.PNR)
        // }
        // if (params) {
        //     fetchBookingDetails(params);
        // }
        loadData()
    }, []);

    const handlePrint = () => {
        window.print();
    };
    console.log(data, "dattta--28---")

    return (
        <div className="d-flex justify-content-center p-2">
            <i onClick={handlePrint} className="print-button">
                🖨️
            </i>
            <title>Invoice</title>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=2.0"
            />
            <link href="images/faviconImage/favicon.gif" rel="shortcut icon"/>
            <link href="Style/common.css" rel="Stylesheet" type="text/css"/>
            <div
                style={{
                    position: "relative",
                    backgroundImage: "url('images/invoiceBgImage.png')",
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    padding: "50px",
                }}
            >
                <div className="backbutton" id="backbutton"/>
                <table align="center" className="table-style">
                    <tbody>
                    <tr>
                        <td>
                            <table
                                cellPadding={0}
                                align="center"
                                cellSpacing={0}
                                border={0}
                                width="100%"
                                className="table-header"
                            >
                                <tbody>
                                <tr className={""}>
                                    <td>
                                        <img
                                            src="images/Logo-black-invoice.png"
                                            className={"logoImage"}
                                            height={55}
                                            alt="Logo"
                                        />
                                    </td>
                                    <td className={"topName"}>INVOICE</td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div
                                width="100%"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontFamily: "Poppins",
                                }}
                            >
                                <div>
                                    <br/>
                                    From <br/>
                                    <span
                                        style={{
                                            "font-size": "25px",
                                            "font-weight": "bold",
                                        }}
                                    >
                                        X Travel World
                    </span>
                                    <br/>
                                </div>
                                <div style={{display: "flex"}}>
                                    <div>
                      <span
                          style={{
                              color: "#999999",
                              fontFamily: "Poppins",
                          }}
                      >
                        Invoice No:
                      </span>
                                        <br/>
                                        <span
                                            style={{
                                                color: "#999999",
                                                fontFamily: "Poppins",
                                            }}
                                        >
                        Invoice Date:
                      </span>
                                        <br/>
                                        <span style={{color: "#999999", fontFamily: "Poppins"}}>
                        PNR No:
                      </span>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: "right",
                                            marginLeft: "10px",
                                            fontFamily: "Poppins",
                                        }}
                                    >
                                        <span>{data && data.InvoiceNo && data.InvoiceNo}</span>
                                        <br/>
                                        <span>
                        {data && data.InvoiceCreatedOn
                        && moment(data.InvoiceCreatedOn).format('DD/MMM/YY')
                        }
                      </span>
                                        <br/>
                                        <span>{pnr && pnr}</span>
                                    </div>
                                </div>
                            </div>
                            <table cellPadding={0} cellSpacing={0} border={0} width="100%">
                                <tbody>
                                <tr>
                                    <td
                                        style={{
                                            padding: "15px 5px",
                                            textAlign: "left",
                                            lineHeight: "1.4",
                                        }}
                                    >
                        <span style={{fontSize: "16px", fontWeight: "bold"}}>
                          Regd Office:{" "}
                        </span>
                                        <span style={{fontSize: "16px"}}>
                                            SCO-178, 2nd Floor
                                        </span>
                                        <br/>
                                        <span style={{fontSize: "16px", fontWeight: "bold"}}>
                          Email:{" "}
                        </span>
                                        <span style={{fontSize: "16px"}}>
                          enquiry@xtravelworld.com
                        </span>
                                        <br/>
                                        <span style={{fontSize: "16px", fontWeight: "bold"}}>
                          Web:{" "}
                        </span>
                                        <a
                                            href="https://xtravelworld.com/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                fontSize: "16px",
                                                textDecoration: "none",
                                                color: 'blue'
                                            }}
                                        >
                                            www.xtravelworld.com
                                        </a>

                                        <br/>
                                        <span style={{fontSize: "16px", fontWeight: "bold"}}>
                          Phone:{" "}
                        </span>
                                        <span style={{fontSize: "16px"}}>
                          1800-102-0340
                        </span>
                                        <br/>
                                        <span style={{fontSize: "16px", fontWeight: "bold"}}>
                          State: Chandigarh
                        </span>
                                        <span style={{fontSize: "16px"}}>
                          {data && data.state && data.state}
                        </span>
                                        <br/>
                                        <span style={{fontWeight: "bold", fontSize: "12px"}}>
                          GSTIN: 04AACCU8256B1ZK
                        </span>
                                        <br/>
                                    </td>
                                    <td
                                        style={{
                                            padding: "15px 5px",
                                            textAlign: "right",
                                            lineHeight: "1.4",
                                        }}
                                    >
                                        Bill to
                                        <br/>
                                        <span
                                            style={{
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                            }}
                                        >
                          {user && user.companyName && user.companyName}
                        </span>
                                        <br/>
                                        <br/>
                                        <span style={{fontSize: "16px", fontWeight: "bold"}}>
                          Owner Name:{" "}
                        </span>
                                        <span style={{fontSize: "16px"}}>
                          {user && user.name && user.name}
                        </span>
                                        <br/>
                                        <span style={{fontSize: "16px", fontWeight: "bold"}}>
                          Address:{" "}
                        </span>
                                        <span style={{fontSize: "16px"}}>
                                            {user && `${user.cityName}, ${user.stateName}, ${user.countryName}`}
                        </span>
                                        <br/>
                                        <span style={{fontSize: "16px", fontWeight: "bold"}}>
                          Phone:{" "}
                        </span>
                                        <span style={{fontSize: "16px"}}>
                          {user && user.mobile && user.mobile}
                        </span>
                                        <br/>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={3} style={{"padding-top": "5px"}}>
                                        <table
                                            cellPadding={5}
                                            cellSpacing={0}
                                            border={0}
                                            width="100%"
                                            style={{
                                                "font-size": "11px",
                                                border: "1px solid #9CBAD6",
                                            }}
                                        >
                                            <tbody>
                                            <tr style={{background: "#F0D39D"}}>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "3%"}}
                                                >
                                                    S No.
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "5%"}}
                                                >
                                                    Ticket No
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "8%"}}
                                                >
                                                    Sectors
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "6%"}}
                                                >
                                                    Flight
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "5%"}}
                                                >
                                                    PAX Name
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "5%"}}
                                                >
                                                    Type
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "8%"}}
                                                >
                                                    Class
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "5%"}}
                                                >
                                                    Fare
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "5%"}}
                                                >
                                                    OT Tax
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "8%"}}
                                                >
                                                    K3/GST
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "6%"}}
                                                >
                                                    YQ Tax
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "6%"}}
                                                >
                                                    YR Tax
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "6%"}}
                                                >
                                                    Bag.Ch.
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "8%"}}
                                                >
                                                    Meal Ch.
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "8%"}}
                                                >
                                                    Seat Ch.
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "8%"}}
                                                >
                                                    Sp Service Ch.
                                                </td>
                                                <td
                                                    className="table-header-cell"
                                                    style={{width: "8%"}}
                                                >
                                                    Service Charges
                                                </td>
                                                <td className="table-header-cell">
                                                    Global Pr. Ch.
                                                </td>
                                            </tr>
                                            {data &&
                                            data.Passenger &&
                                            data.Passenger.length > 0 &&
                                            data.Passenger.map((ticket, index) => (
                                                <tr key={index}>
                                                    <td className="table-cell">{index + 1}</td>
                                                    <td className="table-cell">
                                                        {ticket &&
                                                        ticket.Ticket &&
                                                        ticket.Ticket.ValidatingAirline && (
                                                            <>
                                                                {ticket.Ticket.ValidatingAirline}
                                                                <br/>
                                                            </>
                                                        )}

                                                        {ticket &&
                                                        ticket.Ticket &&
                                                        ticket.Ticket.TicketNumber && (
                                                            <>
                                                                {ticket.Ticket.TicketNumber}
                                                                <br/>
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="table-cell">
                                                        {data && data.Origin && data.Origin}-{data && data.Destination && data.Destination}
                                                    </td>
                                                    <td className="table-cell">
                                                        {data && data.Segments.length > 0 && data.Segments[0].Airline.AirlineCode}
                                                        {data && data.Segments.length > 0 && data.Segments[0].Airline.FlightNumber}
                                                    </td>
                                                    <td
                                                        className="table-cell"
                                                        style={{"word-wrap": "break-word"}}
                                                    >
                                                        {ticket.Title}<br/>
                                                        {ticket.FirstName}<br/>
                                                        {ticket.LastName}
                                                    </td>
                                                    <td className="table-cell">{passengerTypeObject[ticket.PaxType] || 'Unknown'}</td>
                                                    <td className="table-cell">{ticket.class}</td>
                                                    <td className="table-cell">{ticket.Fare.BaseFare}</td>
                                                    <td className="table-cell">
                                                        {ticket.Fare.TaxBreakup.find(item => item.key === 'OT')?.value || 0}
                                                    </td>
                                                    <td className="table-cell">
                                                        {ticket.Fare.TaxBreakup.find(item => item.key === 'K3')?.value || 0}
                                                    </td>
                                                    <td className="table-cell">{ticket.Fare.YQTax || 0}</td>
                                                    <td className="table-cell">
                                                        {ticket.Fare.TaxBreakup.find(item => item.key === 'YR')?.value || 0}
                                                    </td>
                                                    <td className="table-cell">{ticket.Fare.TotalBaggageCharges || 0}</td>
                                                    <td className="table-cell">
                                                        {ticket.Fare.TotalMealCharges || 0}
                                                    </td>
                                                    <td className="table-cell">
                                                        {ticket.Fare.TotalSeatCharges || 0}
                                                    </td>
                                                    <td className="table-cell">
                                                        {ticket.Fare.TotalSpecialServiceCharges || 0}
                                                    </td>
                                                    <td className="table-cell">
                                                        {ticket.Fare.ServiceFee || 0}
                                                    </td>
                                                    <td className="table-cell">
                                                        {ticket.Fare.ChargeBU.find(item => item.key === 'GLOBALPROCUREMENTCHARGE')?.value || 0}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left" valign="top" width="48%">
                                        <br/>
                                        <br/>
                                        <br/>
                                        <div>
                                            <span style={{fontWeight: "bold"}}>Note: </span>{" "}
                                            Voidation and Refund as per fare rules
                                        </div>
                                        <br/>
                                        <br/>
                                        <div>
                          <span style={{fontWeight: "bold"}}>
                            Billed by:{" "}
                          </span>{" "}
                                            X Travel World
                                        </div>
                                        <div>
                          <span style={{fontWeight: "bold"}}>
                            Ticket by:{" "}
                          </span>{" "}
                                            X Travel World
                                        </div>
                                        <div>
                          <span style={{fontWeight: "bold"}}>
                            Invoice Status:{" "}
                          </span>{" "}
                                            {data && data.InvoiceStatus && data.InvoiceStatus}
                                        </div>
                                        <div>
                          <span style={{fontWeight: "bold"}}>
                            Agent Remarks:{" "}
                          </span>{" "}
                                            {data && data.AgentRemarks && data.AgentRemarks}
                                        </div>
                                    </td>
                                    <td width="52%">
                                        <table className="gross-table-whole">
                                            <tbody className="gross-table">
                                            <tr
                                                style={{
                                                    "font-weight": "bold",
                                                    "border-bottom": "1px solid #E1E1E1",
                                                }}
                                            >
                                                <b>Amount Details</b>
                                                <br/>
                                                <br/>
                                            </tr>
                                            <tr>
                                                <td
                                                    align="left"
                                                    width={120}
                                                    style={{color: "#808080", fontWeight: "bold"}}
                                                >
                                                    Gross:
                                                </td>
                                                <td align="right">
                                                    <b style={{fontFamily: "Swis721 Cn BT"}}>
                                                        {data && data.Fare && data.Fare.OfferedFare && data.Fare.OfferedFare}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left" style={{color: "#808080"}}>
                                                    Commission Earned: (Less)
                                                </td>
                                                <td align="right">
                                                    <b style={{fontFamily: "Swis721 Cn BT"}}>
                                                        {data && data.Fare && data.Fare.CommissionEarned && data.Fare.CommissionEarned}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left" style={{color: "#808080"}}>
                                                    Tra Fee: (Add)
                                                </td>
                                                <td align="right">
                                                    <b style={{fontFamily: "Swis721 Cn BT"}}>
                                                        0
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left" style={{color: "#808080"}}>
                                                    TDS Deducted: (Add)
                                                </td>
                                                <td align="right">
                                                    <b style={{fontFamily: "Swis721 Cn BT"}}>
                                                        0
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left" style={{color: "#808080"}}>
                                                    CGST @0%: (Add)
                                                </td>
                                                <td align="right">
                                                    <b style={{fontFamily: "Swis721 Cn BT"}}>
                                                        0
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left" style={{color: "#808080"}}>
                                                    SGST @0%: (Add)
                                                </td>
                                                <td align="right">
                                                    <b style={{fontFamily: "Swis721 Cn BT"}}>
                                                        0
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left" style={{color: "#808080"}}>
                                                    IGST @0%: (Add)
                                                </td>
                                                <td align="right">
                                                    <b style={{fontFamily: "Swis721 Cn BT"}}>
                                                        0
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    align="left"
                                                    style={{color: "#808080", fontWeight: "bold"}}
                                                >
                                                    Net Amount
                                                </td>
                                                <td align="right">
                                                    <b style={{fontFamily: "Swis721 Cn BT"}}>
                                                        {data && data.InvoiceAmount && data.InvoiceAmount}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    align="left"
                                                    style={{color: "#808080", fontWeight: "bold"}}
                                                >
                                                    Net Receivable
                                                </td>
                                                <td align="right">
                                                    <b style={{fontFamily: "Swis721 Cn BT"}}>
                                                        {data && data.InvoiceAmount && data.InvoiceAmount}
                                                    </b>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{"font-weight": "bold"}}>
                                        GST Details :
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <table
                                            cellPadding={5}
                                            cellSpacing={0}
                                            border={0}
                                            width="100%"
                                            style={{
                                                "font-size": "13px",
                                                border: "1px solid #9CBAD6",
                                                "border-collapse": "collapse",
                                                "text-align": "left",
                                            }}
                                        >
                                            <tbody>
                                            <tr style={{background: "#F0D39D"}}>
                                                <th className={"table-header-cell"}>
                                                    Service Description
                                                </th>
                                                <th className={"table-header-cell"}>SAC</th>
                                                <th className={"table-header-cell"}>
                                                    Taxable Value
                                                </th>
                                                <th className={"table-header-cell"}>CGST @ 0%</th>
                                                <th className={"table-header-cell"}>SGST @ 0%</th>
                                                <th className={"table-header-cell"}>
                                                    IGST @ 18%
                                                </th>
                                                <th className={"table-header-cell"}>Total</th>
                                            </tr>
                                            {data &&
                                            data.gstDetails &&
                                            data.gstDetails.length > 0 &&
                                            data.gstDetails.map((gst, index) => (
                                                <tr key={index}>
                                                    <td className={"table-cell"}>
                                                        {gst.serviceDescription}
                                                    </td>
                                                    <td className={"table-cell"}>{gst.sac}</td>
                                                    <td className={"table-cell"}>
                                                        {gst.taxableValue}
                                                    </td>
                                                    <td className={"table-cell"}>{gst.cgst}</td>
                                                    <td className={"table-cell"}>{gst.sgst}</td>
                                                    <td className={"table-cell"}>{gst.igst}</td>
                                                    <td className={"table-cell"}>{gst.total}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        colSpan={2}
                                        style={{"font-weight": "bold", paddingTop: "8px"}}
                                        className={"mt10"}
                                    >
                                        Passenger GST Details:
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <table
                                            cellPadding={5}
                                            cellSpacing={0}
                                            border={0}
                                            width="100%"
                                            style={{
                                                "font-size": "13px",
                                                border: "1px solid #9CBAD6",
                                                "border-collapse": "collapse",
                                                "text-align": "left",
                                            }}
                                        >
                                            <tbody>
                                            <tr style={{background: "#F0D39D"}}>
                                                <th className={"table-header-cell"}>
                                                    Lead Pax Name
                                                </th>
                                                <th className={"table-header-cell"}>
                                                    GST Number
                                                </th>
                                                <th className={"table-header-cell"}>
                                                    GST Company Contact Number
                                                </th>
                                                <th className={"table-header-cell"}>
                                                    GST Company Address
                                                </th>
                                                <th className={"table-header-cell"}>
                                                    GST Company Email
                                                </th>
                                                <th className={"table-header-cell"}>
                                                    GST Company Name
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className={"table-cell"}>
                                                    {data && data.Passenger && data.Passenger.length > 0 && `${data.Passenger[0].Title} ${data.Passenger[0].FirstName} ${data.Passenger[0].LastName}`}
                                                </td>
                                                <td className={"table-cell"}>
                                                    {data && data.Passenger && data.Passenger.length > 0 && data.Passenger[0].GSTNumber && data.Passenger[0].GSTNumber}
                                                </td>
                                                <td className={"table-cell"}>
                                                    {data && data.Passenger && data.Passenger.length > 0 && data.Passenger[0].GSTCompanyContactNumber && data.Passenger[0].GSTCompanyContactNumber}
                                                </td>
                                                <td className={"table-cell"}>
                                                    {data && data.Passenger && data.Passenger.length > 0 && data.Passenger[0].GSTCompanyEmail && data.Passenger[0].GSTCompanyEmail}
                                                </td>
                                                <td className={"table-cell"}>
                                                    {data && data.Passenger && data.Passenger.length > 0 && data.Passenger[0].GSTCompanyAddress && data.Passenger[0].GSTCompanyAddress}
                                                </td>
                                                <td className={"table-cell"}>
                                                    {data && data.Passenger && data.Passenger.length > 0 && data.Passenger[0].GSTCompanyName && data.Passenger[0].GSTCompanyName}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{"vertical-align": "top"}}>
                                        <b
                                            style={{
                                                "text-decoration": "underline",
                                                color: "red",
                                            }}
                                        >
                                            Terms & Conditions :
                                        </b>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <table>
                                            <tbody>
                                            <tr style={{fontSize: "10px"}}>
                                                <td style={{verticalAlign: "top"}}>
                                                    <b>IMP:</b>
                                                </td>
                                                <td>This is computer generated invoice signature not required
                                                </td>
                                            </tr>
                                            <tr style={{fontSize: "10px"}}>
                                                <td style={{verticalAlign: "top"}}>
                                                    <b>IMP :</b>
                                                </td>
                                                <td>All Cases & Disputes are subject to New Delhi Jurisdiction.
                                                </td>
                                            </tr>
                                            <tr style={{fontSize: "10px"}}>
                                                <td style={{verticalAlign: "top"}}>
                                                    <b>IMP :</b>
                                                </td>
                                                <td>Refunds & Cancellations are subject to Airlines approval.
                                                </td>
                                            </tr>
                                            <tr style={{fontSize: "10px"}}>
                                                <td>
                                                    <b>IMP :</b>
                                                </td>
                                                <td>Service charges as included above are to be collected from the
                                                    customers on our behalf.
                                                </td>
                                            </tr>
                                            <tr style={{fontSize: "10px"}}>
                                                <td style={{verticalAlign: "top"}}>
                                                    <b>CHEQUE :</b>
                                                </td>
                                                <td>Must be drawn in favour of 'TRAVEL BOUTIQUE ONLINE'.
                                                </td>
                                            </tr>
                                            <tr style={{fontSize: "10px"}}>
                                                <td style={{verticalAlign: "top"}}>
                                                    <b>LATE PAYMENT :</b>
                                                </td>
                                                <td>Interest @ 24% per annum will be charged on all outstanding bills
                                                    after due date.
                                                </td>
                                            </tr>
                                            <tr style={{fontSize: "10px"}}>
                                                <td style={{verticalAlign: "top"}}>
                                                    <b>VERY IMP. :</b>
                                                </td>
                                                <td>Kindly check all details carefully to avoid un-necessary
                                                    complications.
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BookingDetails;
