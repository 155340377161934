import React from "react"
import {Modal} from "../../components/Elements";
import FlightSeat from "../../assets/img/seatPrefer.png"
import AirportImg from "../../assets/img/airport.png";

const SeatPreferences = (props) => {
    let {visible, onClose, onProceed} = props;
    return (

        <Modal visible={visible} width={'40%'} onClose={onClose} className={'popup-confirm'}>
            <div className={'mt-1 alert-popup'}>

                <h3 className={'mb-4'}>Enhance the flight experience with add-on !</h3>
                <p className={'mb-4'}>
                    Before proceeding , would you like to add any ancillary services?

                </p>
                <div className={'jcc mt-4'}>
                    <div className={'icon-box'}>
                        <img src={FlightSeat} />
                        <span>Seat Preference</span>
                    </div>
                </div>
                <div className={'align-center mt-3 gap-3 mb-3'}>
                    {/*<div className="d-flex justify-content-center gap-3 mb-2">*/}
                    <button
                        className="customYesBtn"
                        onClick={onClose}
                    >
                        Add Ancillary
                    </button>
                    <button
                        className="customYesBtn ms-3"
                        onClick={onProceed}
                    >
                        Proceed
                    </button>

                    {/*</div>*/}

                </div>
            </div>

        </Modal>
    )
}
export default SeatPreferences
