import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import _ from "lodash";
import {getWalletBalanceFxn, lccFlightTicketFxn} from "../../flightSearch/actions";
import TickIcon from "../../../assets/img/tick.png";
import {notification} from "../../../components/Elements";
import {Card} from "../../../components/Elements";
import {WalletIcon} from "../../../components/images"
import {formatCurrency} from "../../../components/utils/appUtils";

const PaymentComponent = (props) => {
    const [selectedPayment, setSelectedPayment] = useState('mastercard');
    let {passengerState, selectedFlight, fareObject, traceId, state, totalPaidAmount, callbackState} = props;
    let navigate = useNavigate()
    let dispatch = useDispatch()
    let [walletBalance, setWalletBalance] = useState(0)
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
    };
    const events = {
        loadBalance: async () => {
            let {success, xTravelWallet} = await dispatch(getWalletBalanceFxn());
            if (success) {
                setWalletBalance(xTravelWallet)
            }
        }
    }
    useEffect(() => {
        events.loadBalance()
    }, [])


    return (
        <div className="passenger-details">
            <div className="title">
                <h5 className="w-100 justify-content-start">Payments</h5>
            </div>

            <div className="payment-method">
                <h4>Choose Payment Method</h4>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link active"
                            id="deposit-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#deposit-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="deposit-tab-pane"
                            aria-selected="true"
                        >
                            Deposit
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link"
                            id="card-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#card-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="card-tab-pane"
                            aria-selected="false"
                        >
                            Credit/Debit Card
                        </button>
                    </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                    <div
                        className="tab-pane fade show active"
                        id="deposit-tab-pane"
                        role="tabpanel"
                        aria-labelledby="deposit-tab"
                        tabIndex="0">
                        <p className={'mt-3'}>
                            <Card className={'travel-wallet'} round={10}>
                                <div className={'d-flex'}>
                                    <img src={WalletIcon} height={60}/>
                                    <div>
                                        <div className={'small-1'}>Balance :</div>
                                        <div className={'balance'}>
                                            {formatCurrency(walletBalance)}
                                        </div>
                                        <small className="d-block">Current X Travel World Wallet Balance</small>
                                    </div>
                                </div>
                            </Card>
                        </p>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="card-tab-pane"
                        role="tabpanel"
                        aria-labelledby="card-tab"
                        tabIndex="0"
                    >
                        <div className="choose-mode">
                            <ul>
                                <li>
                                    <input
                                        type="radio"
                                        name="choose"
                                        value="mastercard"
                                        onChange={handlePaymentChange}
                                        checked={selectedPayment === 'mastercard'}
                                    />
                                    <label><img src="images2/master-card.png" alt="Mastercard"/></label>
                                </li>
                                <li>
                                    <input
                                        type="radio"
                                        name="choose"
                                        value="paypal"
                                        onChange={handlePaymentChange}
                                        checked={selectedPayment === 'paypal'}
                                    />
                                    <label><img src="images2/pay-pal.png" alt="PayPal"/></label>
                                </li>
                                <li>
                                    <input
                                        type="radio"
                                        name="choose"
                                        value="visa"
                                        onChange={handlePaymentChange}
                                        checked={selectedPayment === 'visa'}
                                    />
                                    <label><img src="images2/visa.png" alt="Visa"/></label>
                                </li>
                                <li>
                                    <input
                                        type="radio"
                                        name="choose"
                                        value="wallet"
                                        onChange={handlePaymentChange}
                                        checked={selectedPayment === 'wallet'}
                                    />
                                    <label>Wallet</label>
                                </li>
                                <li>
                                    <input
                                        type="radio"
                                        name="choose"
                                        value="upi"
                                        onChange={handlePaymentChange}
                                        checked={selectedPayment === 'upi'}
                                    />
                                    <label>UPI</label>
                                </li>
                            </ul>
                        </div>

                        <form>
                            <div className="row row-gap-3">
                                <div className="col-lg-6">
                                    <input type="number" className="form-control" placeholder="Card Number"/>
                                </div>
                                <div className="col-lg-6">
                                    <input type="text" className="form-control" placeholder="Expiration Date MM/YY"/>
                                </div>
                                <div className="col-lg-6">
                                    <input type="number" className="form-control" placeholder="CVV"/>
                                </div>
                                <div className="col-lg-6">
                                    <input type="text" className="form-control" placeholder="Name on Card"/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="terms-check d-flex flex-wrap gap-2 gap-lg-5 flex-lg-nowrap mt-3 align-items-center">
                <div className="d-flex gap-2 text-nowrap align-items-center">
                    <input
                        type="checkbox"
                        id="acceptTerms"
                        checked={isTermsAccepted}
                        onChange={(e) => setIsTermsAccepted(e.target.checked)}
                        style={{display: "none"}}
                    />
                    <span onClick={() => setIsTermsAccepted(!isTermsAccepted)} style={{cursor: "pointer"}}>
                  <img src={TickIcon} alt={'icon'}
                       style={{width: '22px'}}
                  />
                </span>
                    <label htmlFor="acceptTerms">
                        I accept <a href="#">terms & conditions</a>
                    </label>
                </div>
                <button className="btn btn-primary w-100 mx-auto" onClick={() => {
                    if (walletBalance >= totalPaidAmount) {
                        callbackState();
                    } else {
                        notification.warning({
                            message: 'Insufficient agent wallet balance.'
                        })
                    }
                }}
                        disabled={!isTermsAccepted}
                >Pay Now
                </button>
            </div>
        </div>
    );
};

export default PaymentComponent;
