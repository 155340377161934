import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash"
import {flightsFearQuoteFxn, lccFlightTicketFxn, ticketBySessionIdFxn} from "../flightSearch/actions";
import {notification} from "../../components/Elements";
import RegisterBanner from "../../components/pageComponents/registerBanner";
import {Link, useLocation, useNavigate} from "react-router-dom";
import ChangePrice from "../ModalComponent/changePrice";
import OneWayBooking from "./oneWayBooking";
import RoundTripBooking from "./roundTripBooking";
import OverseasBooking from "./overseasBooking";
import {bookSeatsFxn} from "../ticketList/action";

const contactInitState = {
    email: "",
    contactNo: ""
}
const Checkout = () => {
    const [bookingType, setBookingType] = useState("Outbound")
    const [ssrResponse, setSsrResponse] = useState({});
    const [sessionId, setSessionId] = useState("");

    const {flightObj, traceId, selectedFlight, fareQuoteObj, flightBookingType} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj,
        selectedFlight: state.flightsReducer.selectedFlight,
        traceId: state.flightsReducer.traceId,
        fareQuoteObj: state.flightsReducer.fareQuoteObj,
        flightBookingType: state.flightsReducer.flightBookingType,
    }))
    const location = useLocation();
    const stateParams = location.state?.params;
    useEffect(() => {
        if (stateParams && stateParams.bookingType) {
            setBookingType(stateParams.bookingType)
        }
        if (stateParams && stateParams.sessionId) {
            setSessionId(stateParams.sessionId)
        }
    }, [stateParams])
    const [state, setState] = useState(contactInitState)
    let dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(1)
    const [fareObject, setFareObject] = useState({})
    const [outboundFareObj, setOutboundFareObj] = useState({})


    const [fareState, setFareState] = useState({
        oldFare: {},
        newFare: {},
        quoteFare: {},
        visible: false
    })


    useEffect(() => {
        if (bookingType === "Inbound") {
            dispatch({ type: "RESET_SSR_RESPONSE" });
            setSsrResponse({})
        }
        events.fareQuote();
    }, [traceId, selectedFlight, bookingType])




    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        fareQuote: async () => {
            console.log('==========ddjdj');
            if (selectedFlight.mappedOutBoundFlights) {
                let bookingTypeValue = bookingType
                if (location.state && location.state.params && location.state.params.bookingType) {
                    bookingTypeValue = location.state.params.bookingType;
                }
                let bookingFlightData = bookingTypeValue == "Inbound" ? selectedFlight['mappedInBoundFlights'] : selectedFlight['mappedOutBoundFlights'];
                if (bookingFlightData) {
                    if (traceId && bookingFlightData['ResultIndex']) {
                        let obj = {
                            traceId: traceId,
                            resultIndex: bookingFlightData['ResultIndex'],
                            bookingTypeValue
                        }
                        const {data, success, ssrResponse} = await dispatch(flightsFearQuoteFxn(obj));
                        // console.log(data, success, "flight --------1")
                        if (success) {
                            events.checkPriceDifferent(data, obj);
                            setSsrResponse(ssrResponse);
                            dispatch({type: "SET_SSR_RESPONSE", ssrResponse: ssrResponse});
                        }
                    }
                }
            } else {
                // console.log(traceId, selectedFlight['ResultIndex'], "selectedFlight['ResultIndex']");
                if (traceId && selectedFlight['ResultIndex']) {
                    // console.log('if part');
                    let obj = {
                        traceId: traceId,
                        resultIndex: selectedFlight['ResultIndex'],
                        bookingTypeValue: bookingType
                    }
                    const {data, success, ssrResponse} = await dispatch(flightsFearQuoteFxn(obj));
                    // console.log(data, success, "flight --------2")
                    if (success) {
                        events.checkPriceDifferent(data, obj);
                        setSsrResponse(ssrResponse);
                        dispatch({type: "SET_SSR_RESPONSE", ssrResponse: ssrResponse});
                    }
                }
            }
        },
        checkPriceDifferent: (data, obj) => {
            let {Fare: quoteFare} = data;
            // console.log(quoteFare, "qiootttt")
            if (quoteFare['PublishedFare'] == quoteFare['PublishedFare'] && quoteFare['OfferedFare'] == quoteFare['OfferedFare']) {
                // console.log(data, "dattat")
                setFareObject(data);
                // events.getBookSeatsFxn(obj);
            } else {
                dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data})
                setFareState({
                    oldFare: quoteFare,
                    newFare: quoteFare,
                    quoteFare: data,
                    visible: true
                })
            }
        },
        getBookSeatsFxn: async (obj) => {
            console.log(obj, "obj-----")
            const {data} = await dispatch(bookSeatsFxn(obj));
            // console.log('------------------------');
            // console.log('----------ssr--------------', data);
            // console.log('------------------------');
            dispatch({type: "SET_SSR_RESPONSE", ssrResponse: data});
            console.log(data, "datta-ssr--")
            setSsrResponse(data);
        },
        closeChangePriceModal: (data) => {
            setFareState({
                oldFare: {},
                newFare: {},
                quoteFare: {},
                visible: false,
            });
        },
        submitChangePriceModal: (data) => {
            setFareObject(data);
            dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data});
            setFareState({
                oldFare: {},
                newFare: {},
                quoteFare: {},
                visible: false,
            });
        },
        addPassenger: () => {
            setActiveTab(2)
            window.scrollTo(0, 0)
        },

    }

    // console.log(fareState, "farestate=--------------")

    return (
        <div>
            {flightObj.journeyType == "2" ?
                <>
                    {flightBookingType == "overseasFlight" ?
                        <OverseasBooking flightObj={flightObj} traceId={traceId} selectedFlight={selectedFlight}
                                         fareObject={fareObject}
                                         bookingType={bookingType}
                                         ssrResponse={ssrResponse}
                                         flightBookingType={flightBookingType}
                                         fareQuoteObj={fareQuoteObj}/> :
                        <RoundTripBooking flightObj={flightObj} traceId={traceId} selectedFlight={selectedFlight}
                                          fareObject={fareObject}
                                          ssrResponse={ssrResponse}
                                          bookingSessionId={sessionId}
                                          bookingType={bookingType}
                                          flightBookingType={flightBookingType}
                                          fareQuoteObj={fareQuoteObj}/>}
                </> : null}

            {flightObj.journeyType == "1" ?
                <div>
                    <OneWayBooking
                        flightObj={flightObj}
                        traceId={traceId}
                        selectedFlight={selectedFlight}
                        fareObject={fareObject}
                        flightBookingType={flightBookingType}
                        fareQuoteObj={fareQuoteObj}/>
                </div> : null}

            <RegisterBanner/>
            {fareState.visible ?
                <ChangePrice {...fareState}
                             onSubmit={events.submitChangePriceModal}
                             onClose={events.closeChangePriceModal}/> : null}
        </div>
    );
}

export default Checkout
