import OneWayFlightInfoBox from "../../flightSearchResult/oneWayFlightInfoBox";
import React from "react";
import moment from "moment";

const flightDate = (date) => {
    return moment(date).format("dddd, MMMM, Do, YYYY")
}

const FlightDestination = (props) => {
    let {flightObj} = props;
    return (
        <>
            <div className="flight-destimation-time">
                <div className="row row-gap-3 align-items-center">
                    <div className="col-lg-4">
                        <div className="d-flex align-items-center"
                             style={{gap: '30px'}}>
                            <h6 className="m-0 white">{flightObj.origin}</h6>
                            <span><img src="../images2/plane.png" width="22"
                                       alt="plane"/></span>
                            <h6 className="m-0 white">{flightObj.destination}</h6>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <p className="m-0">on {flightDate(flightObj.preferredDepartureTime)}</p>
                    </div>
                    <div className="col-lg-4">
                        {/*<p className="m-0">21hr 10min</p>*/}
                    </div>
                </div>
            </div>

        </>
    )
}

const FlightInfoComponent = (props) => {
    let {flightObj, selectedFlight, bookingType = '', sessionId, flightBookingType} = props;

    return (
        <>
            <div className={'mb-4 find-flight-main'}>
                <FlightDestination flightObj={flightObj}/>
                <div className={'accordion-flight-track'}>
                    {flightObj.journeyType == 2 ?
                        <div className={'row'}>
                            {selectedFlight.mappedInBoundFlights || selectedFlight.mappedOutBoundFlights ? (
                                <>
                                    <div className={'col-md-6'}>
                                        {selectedFlight.mappedOutBoundFlights && (
                                            <OneWayFlightInfoBox
                                                title={'Outbound'}
                                                journeyType={flightObj.journeyType}
                                                data={selectedFlight.mappedOutBoundFlights}
                                                bookingType={bookingType}
                                            />
                                        )}
                                    </div>
                                    <div className={'col-md-6'}>
                                        {selectedFlight.mappedInBoundFlights && (
                                            <OneWayFlightInfoBox
                                                title={'Inbound'}
                                                sessionId={sessionId}
                                                journeyType={flightObj.journeyType}
                                                data={selectedFlight.mappedInBoundFlights}
                                                bookingType={bookingType}
                                            />
                                        )}
                                    </div>
                                </>
                            ) : null}
                        </div> :
                        <>
                            <OneWayFlightInfoBox data={selectedFlight} journeyType={flightObj.journeyType}/>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default FlightInfoComponent
