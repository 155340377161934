import {Icon} from "../../../components/Elements";
import _ from "lodash";
import {fixed2Digit, passengerTypeObject} from "../../../components/utils/appUtils";
import React, {useEffect, useState} from "react";

const CollapsibleSection = ({id, title, children, isOpen = false}) => {
    const [isExpanded, setIsExpanded] = React.useState(isOpen);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="accordion-item border-1 mb-1">
            <h2 className="accordion-header d-flex align-items-center" id={`heading-${id}`}>
                <button
                    className={`accordion-button d-flex justify-content-between align-items-center customCollapse ${isExpanded ? 'expanded' : ''}`}
                    onClick={toggleExpand}
                    aria-expanded={isExpanded}
                    aria-controls={`collapse-${id}`}
                >
                    {title}
                    <span
                        style={{
                            transform: isExpanded ? "rotate(90deg)" : "rotate(0)",
                            transition: "transform 0.3s",
                        }}
                    >

                    </span>
                </button>
            </h2>
            <div
                id={`collapse-${id}`}
                className={`accordion-collapse collapse ${isExpanded ? "show" : ""}`}
                aria-labelledby={`heading-${id}`}
            >
                <div className="accordion-body p-1">{children}</div>
            </div>
        </div>
    );
};


const SummaryRow = (props) => {
    let {title, published, offered, showIcon = true} = props;
    return (
        <>
            <div className={'summary-grid'}>
                <div>{title}</div>
                <div>
                    {published ?
                        <>
                            {showIcon ? <Icon name={'rupee'}/> : ""}
                            {published}
                        </> : 0}

                </div>
                {/*   {offered !== undefined ? <div>
                    {showIcon ? <Icon name={'rupee'}/> : ""}
                    {offered}
                </div> : null}*/}
            </div>
        </>
    )
}
const ReturnFlightSummary = (props) => {
    let {inboundData, outboundData, passengerState} = props;

    let {FareBreakdown: fareInbound} = inboundData;
    let {FareBreakdown: fareOutbound} = outboundData;
    // let {adultPassengers, childPassengers} = passengerState;
    // console.log(passengerState, "state----");
    // console.log(fareBreakdown, "fare breakdwin")
    let taxEvents = {
        getSingleTax: (data, taxName) => {
            let findD = _.find(data, (item) => {
                return item.key == taxName;
            });
            return findD ? findD.value : 0;
        },
        getOtherTax: (data) => {
            let totalTax = 0;
            _.each(data, (item) => {
                if (!['YQTax', 'YR'].includes(item.key)) {
                    totalTax += item.value;
                }
            });
            return totalTax;
        },
    };

    const calculateTotals = (fareBreakdown) => {
        return fareBreakdown.reduce(
            (acc, item) => {
                const baseFare = item.BaseFare || 0;
                const tax = item.Tax || 0;
                const otherTax = taxEvents.getOtherTax(item.TaxBreakUp);
                const yqTax = taxEvents.getSingleTax(item.TaxBreakUp, 'YQTax');
                const yrTax = taxEvents.getSingleTax(item.TaxBreakUp, 'YR');

                acc.totalBaseFare += baseFare;
                acc.totalTax += tax;
                acc.totalOtherTax += otherTax;
                acc.totalYQTax += yqTax;
                acc.totalYRTax += yrTax;
                acc.totalAmount += baseFare + tax;

                return acc;
            },
            {
                totalBaseFare: 0,
                totalTax: 0,
                totalOtherTax: 0,
                totalYQTax: 0,
                totalYRTax: 0,
                totalAmount: 0,
            }
        );
    };

    const inboundTotals = calculateTotals(fareInbound || []);
    const outboundTotals = calculateTotals(fareOutbound || []);


    const combinedTotals = {
        totalBaseFare: inboundTotals.totalBaseFare + outboundTotals.totalBaseFare,
        totalTax: inboundTotals.totalTax + outboundTotals.totalTax,
        totalOtherTax: inboundTotals.totalOtherTax + outboundTotals.totalOtherTax,
        totalYQTax: inboundTotals.totalYQTax + outboundTotals.totalYQTax,
        totalYRTax: inboundTotals.totalYRTax + outboundTotals.totalYRTax,
        totalAmount: inboundTotals.totalAmount + outboundTotals.totalAmount,
    };

    return (
        <>
            <div className="detail-box">
                <h4>Fare Summary</h4>
                <SummaryRow published={'Published'} offered={'Offered'} showIcon={false}/>

                {fareInbound && fareInbound.length
                    ? fareInbound.map((item, index) => (
                        <SummaryRow
                            key={`inbound-${index}`}
                            title={<b>{passengerTypeObject[item.PassengerType]}</b>}
                            published={fixed2Digit(item.BaseFare)}
                            offered={fixed2Digit(item.BaseFare / 2)}
                            showIcon={false}
                        />
                    ))
                    : null}

                {fareOutbound && fareOutbound.length
                    ? fareOutbound.map((item, index) => (
                        <SummaryRow
                            key={`outbound-${index}`}
                            title={<b>{passengerTypeObject[item.PassengerType]}</b>}
                            published={fixed2Digit(item.BaseFare)}
                            offered={fixed2Digit(item.BaseFare / 2)}
                            showIcon={false}
                        />
                    ))
                    : null}


                <SummaryRow
                    title={'QT Tax'}
                    published={fixed2Digit(combinedTotals.totalOtherTax)}
                    offered={fixed2Digit(combinedTotals.totalOtherTax / 2)}
                />
                <SummaryRow
                    title={'YQ Tax'}
                    published={fixed2Digit(combinedTotals.totalYQTax)}
                    offered={fixed2Digit(combinedTotals.totalYQTax / 2)}
                />
                <SummaryRow
                    title={'YR Tax'}
                    published={fixed2Digit(combinedTotals.totalYRTax)}
                    offered={fixed2Digit(combinedTotals.totalYRTax / 2)}
                />
                <hr/>
                <SummaryRow
                    title={'Total Fare'}
                    published={<b>{fixed2Digit(combinedTotals.totalAmount)}</b>}
                    offered={<b>{fixed2Digit(combinedTotals.totalAmount / 2)}</b>}
                />
                <hr/>
                <ul>
                    <li>
                        <button className="btn btn-primary w-100" type="button">Pay <Icon
                            name={'rupee'}/> {fixed2Digit(combinedTotals.totalAmount)}</button>
                    </li>
                </ul>
            </div>
        </>
    );
};
const FlightSummary = (props) => {
    let { flightData, passengerState = {}, callBack } = props;
    const { FareBreakdown: fareBreakdown,  } = flightData;
    let { adultPassengers, childPassengers, infantPassengers } = passengerState;
      // console.log(fareBreakdown, "fare breakdown", passengerState)
    const taxEvents = {
        getSingleTax: (data, taxName) => {
            const findD = _.find(data, (item) => item.key === taxName);
            return findD ? findD.value : 0;
        },
        getOtherTax: (data) => {
            let totalTax = 0;
            _.each(data, (item) => {
                if (!["YQTax", "YR"].includes(item.key)) {
                    totalTax += item.value;
                }
            });
            return totalTax;
        },
    };

    const calculateTotals = (fareBreakdown) => {
        return fareBreakdown.reduce(
            (acc, item) => {
                const baseFare = item.BaseFare || 0;
                const tax = item.Tax || 0;
                const otherTax = taxEvents.getOtherTax(item.TaxBreakUp);
                const yqTax = taxEvents.getSingleTax(item.TaxBreakUp, 'YQTax');
                const yrTax = taxEvents.getSingleTax(item.TaxBreakUp, 'YR');
                const totalFare = baseFare + tax;

                acc.totalBaseFare += totalFare;

                acc.totalTax += tax;
                acc.totalOtherTax += otherTax;
                acc.totalYQTax += yqTax;
                acc.totalYRTax += yrTax;
                acc.totalAmount += baseFare + tax;

                return acc;
            },
            {
                totalBaseFare: 0,
                totalTax: 0,
                totalOtherTax: 0,
                totalYQTax: 0,
                totalYRTax: 0,
                totalAmount: 0,
            }
        );
    };
    const transactionFee = flightData?.Fare?.ChargeBU?.find(c => c.key === "TRANSACTIONFEE")?.value || 0;
    const serviceCharges = flightData?.Fare?.OtherCharges || 0;

    const totalFeeAndServiceCharges = transactionFee + serviceCharges;
    const gstOnFeeAndCharges = totalFeeAndServiceCharges * 0.18;

    const totals = calculateTotals(fareBreakdown || []);

    const calculateAncillaryPrices = (passengers = []) => {
        // console.log(passengers, "pseee")
        return passengers?.reduce(
            (totals, passenger) => {
                if (passenger?.Baggage) {
                    totals.baggagePrice += passenger?.Baggage?.Price || 0;
                }
                if (passenger?.MealDynamic) {
                    passenger.MealDynamic?.forEach(meal => {
                        totals.mealPrice += meal?.Price || 0;
                    });
                }
                if (passenger?.SeatDynamic) {
                    passenger.SeatDynamic?.forEach(seat => {
                        totals.seatPrice += seat?.Price || 0;
                    });
                }
                if (passenger?.SpecialServices) {
                    passenger.SpecialServices?.forEach(service => {
                        totals.specialServicesPrice += service?.Price || 0;
                    });
                }
                if (passenger?.BagDynamic) {
                    totals.specialServicesPrice += passenger?.BagDynamic?.Price || 0;
                }
                return totals;
            },
            {baggagePrice: 0, mealPrice: 0, seatPrice: 0, specialServicesPrice: 0}
        );
    };


    const adultAncillaries = calculateAncillaryPrices(adultPassengers);

    const childAncillaries = calculateAncillaryPrices(childPassengers);


    const baggagePrice = adultAncillaries?.baggagePrice + childAncillaries?.baggagePrice;
    const mealPrice = adultAncillaries?.mealPrice + childAncillaries?.mealPrice;
    const seatPrice = adultAncillaries?.seatPrice + childAncillaries?.seatPrice;
    const specialServicesPrice = adultAncillaries?.specialServicesPrice + childAncillaries?.specialServicesPrice;

    const totalAmountWithAncillaries =
        totals.totalAmount + mealPrice + seatPrice + baggagePrice + specialServicesPrice;

       useEffect(()=>{
           callBack(totalAmountWithAncillaries)
       },[totalAmountWithAncillaries])

    return (
        <div className="detail-box">
            <h4>Fare Summary</h4>
            <CollapsibleSection title="Pax-Wise Fare">
                {fareBreakdown.map((item, index) => {
                    const baseFarePerPassenger = item.BaseFare / item.PassengerCount;
                    const tax = item.Tax / item.PassengerCount
                    const totalFare = baseFarePerPassenger + tax;

                    return (
                        <div key={index}>
                            <SummaryRow
                                title={<b>{passengerTypeObject[item.PassengerType]}</b>}
                                published={fixed2Digit(baseFarePerPassenger)}
                                offered={fixed2Digit(baseFarePerPassenger)}
                                showIcon={false}
                            />
                            <SummaryRow
                                title="Tax"
                                published={fixed2Digit(tax)}
                                offered={fixed2Digit(tax)}
                            />
                            <hr style={{ margin: '7px 0' }} />
                            <SummaryRow
                                title="Total"
                                published={fixed2Digit(totalFare)}
                                offered={fixed2Digit(totalFare)}
                                showIcon={false}
                            />
                            <hr style={{ margin: '7px 0' }} />
                        </div>
                    );
                })}
            </CollapsibleSection>


            <CollapsibleSection title="Base Fare">
                {fareBreakdown?.map((item, index) => (
                    <div key={index}>
                        <SummaryRow title={<b>{passengerTypeObject[item['PassengerType']]} × {item['PassengerCount']}</b>}
                                    published={<b>{fixed2Digit(item.BaseFare + item.Tax)}</b>}
                                    offered={<b>{fixed2Digit((item.BaseFare + item.Tax))}</b>}
                                    showIcon={false}
                        />

                    </div>
                ))}
                <hr style={{margin: '7px 0'}}/>
                <SummaryRow
                    title={<b>Total Base Fare</b>}
                    published={<b>{fixed2Digit(fareBreakdown.reduce((total, item) => total + (item.BaseFare + item.Tax), 0))}</b>}
                    offered={<b>{fixed2Digit(fareBreakdown.reduce((total, item) => total + (item.BaseFare + item.Tax), 0))}</b>}
                    showIcon={false}
                />
            </CollapsibleSection>

            <CollapsibleSection title="Additional Charges">
                {baggagePrice > 0 && (
                    <SummaryRow
                        title="Excess Baggage"
                        published={fixed2Digit(baggagePrice)}
                        offered={fixed2Digit(baggagePrice / 2)}
                    />
                )}

                {mealPrice > 0 && (
                    <SummaryRow
                        title="Meal"
                        published={fixed2Digit(mealPrice)}
                        offered={fixed2Digit(mealPrice / 2)}
                    />
                )}

                {seatPrice > 0 && (
                    <SummaryRow
                        title="Seat Charges"
                        published={fixed2Digit(seatPrice)}
                        offered={fixed2Digit(seatPrice / 2)}
                    />
                )}

                {specialServicesPrice > 0 && (
                    <SummaryRow
                        title="Special Services"
                        published={fixed2Digit(specialServicesPrice)}
                        offered={fixed2Digit(specialServicesPrice / 2)}
                    />
                )}
            </CollapsibleSection>

            <hr/>
            <SummaryRow
                title="Total GST"
                published={0}
                offered={0}
            />
            <hr/>
            <ul>

                <li>
                    <li className="total-amount">
                        <p><b>Total Payable Amount</b></p>
                        <span><b><Icon name="rupee"/> {fixed2Digit(totalAmountWithAncillaries)}</b></span>
                    </li>
                </li>
            </ul>
        </div>
    );
};


export {FlightSummary, ReturnFlightSummary}
