import React, {useEffect, useState} from "react";

import SearchFlightMainComponent from "../../flightSearch/searchComponent/SearchFlightMainComponent";

const ModifySearch = (props) => {
    let {flightList, flightObj, setFlightObjState, flightSearch} = props

    useEffect(() => {
        // events.loadAirLine()
    }, [])


    return (
        <>
            <div className="flight-booking">
                <div className="container position-relative">
                    <div className={"tab-content p-3 p-lg-4 bg-white modify_box"}>
                        <SearchFlightMainComponent {...props}
                                                   flightObj={flightObj}
                                                   onClick={(resp) => {
                                                       flightSearch(resp)
                                                   }}/>
                    </div>

                </div>
            </div>

        </>
    );
};

export default ModifySearch;
